<style lang="scss" scoped>
	
	.n-turntable{
		width: 100%;
		height: 100%;
		position: relative;
		overflow: hidden;
		@include n-row2;
		>ul{
			width: 100%;
			flex-shrink: 0;
			height: 100%;
			position: relative;
			transform: translate(0,0);
			@include n-row1;
			align-items: stretch;
			>li{
				flex-shrink: 0;
				width: 100%;
				height: 100%;
				position: relative;
				z-index: 1;
				@include n-row2;
				>div{
					flex-shrink: 0;
					width: 100%;
					height: 100%;
				}
			}
			.n-turntable-cur{
				z-index: 2;
				
			}
		}
		&.n-turntableVert>ul{
			flex-direction: column;
		}
		&.n-turntableReverse>ul{
			justify-content: flex-end;
		}
	}
 
</style>
<template>
	<div :class="{'n-turntable':1,  'n-turntableVert': conf.dir === 'vert', 'n-turntableReverse': conf.order > 0}">
		<ul v-resize="resize" ref="box" :style="{transform: `${dirMap.translate}(${offsetAll||offset}px)`, 'transition-duration': `${duration}ms`}"   >
			<li :idx="item._swiperIdx"  ref="box-item" :class="{'n-turntable-cur':  realCur === idx  }" v-for="item,idx in slideGoods" :key="idx">
				<slot name="default" :item="item" :idx="idx" :conf="conf"></slot>
			</li>
		</ul>
	</div>
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	import swiperComp from '@/components/swiper.vue';
	export const defConf = ()=>({
		list:[],
		dir: 'hori',
		isStop: 1,
		order: -1,
		cur:0,
		speed: 2,
		animCb:null,
	});
	export default{
		name: 'n-turntable',
		props: {
			conf:{
				type: Object,
				default:defConf
			},
		},
		data(){
			
			return{
				id: this.$base.uuid(),
				itemSize:0,
				realCur:0,
				offset: 0,
				offsetAll: 0,
				duration: 0,
				round: 3,
				max:this.conf.list.length,
				
			}
		},
		mounted(){
			this.resize()
			this.anim();
		},
		computed:{
			...swiperComp.computed,
			totalPrize() {
				return this.conf.list.length;
			},
			slideGoods() {
				let list = [];
				if (this.totalPrize < 10) {
					this.round = 5;
				} else if (this.totalPrize < 20) {
					this.round = 4;
				} else if (this.totalPrize < 30) {
					this.round = 2
				} else {
					this.round = 2;
				}
				for (let i = 0; i <= this.round + 1; i++) {
					list.push(...this.conf.list);
				}
				console.log('slideGoods',this.totalPrize, this.round, list)
				return list;
			},
			realList(){
				const list = this.conf.list;
				list.forEach((v,idx)=> v._swiperIdx = idx );
				const res = [...list,...list,...list];
				return this.conf.order > 0 ? res.reverse() : res;
			}
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			},
			'conf.list'(){
				this.max = this.conf.list.length;
			},
		},
		
		methods:{
			getCurOffset(cur = this.conf.cur){
				let {conf:{order},itemSize} = this;
				return  cur * itemSize * order;
			},
			getCurOffset2(cur = this.conf.cur){
				let {conf:{order},itemSize} = this;
				const srcollIndex = this.round * this.totalPrize + cur
				const r = Math.floor(Math.random() * (itemSize / 2 - 10)); // 随机数值一行3个
				const test = (Math.random() < 0.5 ? -1 : 1) * r // 偏差
        const innerWidth = window.document.getElementsByClassName('hlayout')[0].clientWidth
				return this.$refs[`box-item`][srcollIndex].offsetLeft * order + innerWidth / 2  - itemSize / 2 + test;
			},
			posi(offset){
				let {conf:{order}, max,itemSize} = this;
				const circleWidth = max * itemSize;
				if(order < 0 && offset < -1 * circleWidth ) offset =  offset + circleWidth;
				if(order > 0 && offset > circleWidth) offset =  offset - circleWidth;
				return offset;
			},
			resize(){
				const {dirMap:{size} } = this;
				console.log('this.$refs.box', this.$refs.box.offsetWidth, this.$refs.box.marginRight)
				this.itemSize = this.$refs.box[size];
				// 开始时居中(设置第4个元素在中间)
        const innerWidth = window.document.getElementsByClassName('hlayout')[0].clientWidth
				this.offsetAll =  this.$refs[`box-item`] ? (-1 * this.$refs[`box-item`][3].offsetLeft + innerWidth / 2  - this.itemSize / 2 - 4) : 0;
			},
			anim(){
				if(this._isDestroyed) return
				window.requestAnimationFrame(this.anim)
				let {offset, conf:{animCb,speed,isStop,order}} = this;
				if(isStop) return;
				offset += order * speed;
				this.offset = this.posi(offset);
				
				animCb && animCb({vm:this})
			},
			
			...tool({ keyMap:{ mergeConf:1} }),
			
		}
		
		
	}
	
</script>