<style lang="scss" scoped>
	
	.n-swiper{
		width: 100%;
		
		.n-swiper-imgs{
			width: 100%;
			height: 100%;
			position: relative;
			overflow: hidden;
			@include n-row2;
			>ul{
				width: 100%;
				flex-shrink: 0;
				height: 100%;
				position: relative;
				transform: translate(0,0);
				@include n-row1;
				align-items: stretch;
				
				>li{
					flex-shrink: 0;
					width: 100%;
					height: 100%;
					position: relative;
					z-index: 1;
					@include n-row2;
					>div{
						flex-shrink: 0;
						width: 100%;
						height: 100%;
					}
				}
				.n-swiper-sub{
					z-index: 2;
				}
				.n-swiper-cur{
					z-index: 3;
					
				}
				&.n-swiper-imgs-anim{
					transition: all 0.3s;
					>li>div{
						transition: all 0.3s;
					}
				}
			}
			
		}
		.n-swiper-dots{
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 20px;
			gap:30px;
			>li{
				cursor: pointer;
				background: rgba(255,255,255,0.6);
				border-radius: 5px;
				width: 9px;
				height: 9px;
			}
			.n-swiper-dots-cur{
				transition: all 0.3s;
				background: rgb(106,195,75);
				width: 30px;
			}
		}
	
		&.n-swiperVert .n-swiper-imgs{
			flex-direction: column;
		}
	}
</style>
<template>
	<div :class="{'n-swiper':1, 'n-swiperVert': conf.dir === 'vert'}">
	
		<div class="n-swiper-imgs"  @mousedown="onDown" @touchstart="onDown">
			<ul v-resize="resize" ref="box" :class="{'n-swiper-imgsBox':1, 'n-swiper-imgs-anim': !isMove && isAnim}" :style="{transform: `${dirMap.translate}(${offset}px)`}" @transitionend="updateRealCur" >
				<li :idx="item._swiperIdx"   :class="{'n-swiper-cur':  realCur === idx , 'n-swiper-sub':  realCur - 1 === idx || idx === realCur + 1 }" v-for="item,idx in realList" :key="idx">
					
					
					<div @click="()=>(clickCb = (()=> conf.userMove && update(idx - realCur, conf.cur = item._swiperIdx)))"><slot name="default" :item="item" :idx="idx" :conf="conf" ></slot></div>
				</li>
			</ul>
		</div>
		
		<!-- <slot name="dots" v-if="conf.showDots" :conf="conf">
			<ul class="n-swiper-dots" >
				<li v-for="item,idx in conf.list"  :key="idx" :class="{'n-swiper-dots-cur': conf.cur == idx}"></li>
			</ul>
		</slot> -->
	</div>
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		list:[],
		showDots:false,
		cur:0,
		userMove: true,
		dir: 'hori',
	});
	export default{
		name: 'n-swiper',
		props: {
			conf:{
				type: Object,
				default:defConf
			},
		},
		data(){
			
			return{
				id: this.$base.uuid(),
				itemSize:0,
				isAnim:1,
				realCur:0,
				offset: 0,
				sx: 0,
				sy: 0,
				mx: 0,
				my: 0,
				min:0,
				max: this.conf.list.length - 1,
				isMove:false,
				isScrollMove:false,
				isMove2:false,
				downTime:0,
				clickCb:null,
				isNoClick:false,
			}
		},
		mounted(){
			this.resize();
			this.update();
			document.addEventListener('mousemove',this.onMove);
			document.addEventListener('touchmove',this.onMove, {passive:false});
			document.addEventListener('mouseup',this.onUp);
			document.addEventListener('touchend',this.onUp);
		},
		beforeUnmount(){
			document.removeEventListener('mousemove',this.onMove);
			document.removeEventListener('touchmove',this.onMove);
			document.removeEventListener('mouseup',this.onUp);
			document.removeEventListener('touchend',this.onUp);
		},
		computed:{
			dirMap(){
				return ({
					'hori': { size: 'offsetWidth', exactSize:'width', offset: 'left', translate: 'translateX'  },
					'vert':{ size : 'offsetHeight', exactSize:'height', offset: 'top', translate: 'translateY' }
				})[this.conf.dir || 'hori'];
			},
			realList(){
				const list = this.conf.list;
				list.forEach((v,idx)=> v._swiperIdx = idx );
				return [...list,...list,...list];
			}
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			},
			'conf.list'(){
				this.max = this.conf.list.length - 1;
			},
		},
		methods:{
			// 动画结束，更新realCur到3个list的中间list
			updateRealCur(){
				this.$base.debounce.commit('n-swiper' + this.id, async ()=>{
					this.isAnim = 0;
					await new Promise(r=> window.requestAnimationFrame(r) );
					await new Promise(r=> window.requestAnimationFrame(r) );
					let { conf:{list}, realCur,realList, itemSize} = this;
					if (!realList[realCur]) return
					this.realCur = realList[realCur]._swiperIdx + list.length;
					this.offset = -this.realCur * itemSize;
				 
					await new Promise(r=> window.requestAnimationFrame(r) );
					await new Promise(r=> window.requestAnimationFrame(r) );
					this.isAnim = 1;
				},1)
				
			},
			async update(dir = 1){
				this.isAnim = 1;
				let { conf:{list,cur}, realCur,realList,itemSize} = this;
				if(!realList.length) return;
				let idx = realList[realCur]._swiperIdx;
				
				if(dir > 0) idx = (cur < idx ? list.length + cur : cur);
				else idx = (cur > idx ? cur - list.length : cur);	
			
				// 加上左边list.length
				idx+=list.length;
				this.offset = itemSize * -idx;
				this.realCur = idx;
			},
			resize(){
				const {dirMap:{size} } = this;
				this.itemSize = this.$refs.box[size];
				this.update()
			},
			
		
			onDown(e){
				if(!this.conf.userMove) return;
				const [x,y] = this.$base.coordinate.getMouseXYByBox({e,tg: this.$el });
				
				this.isScrollMove = this.isMove2 = false;
				this.sx = this.mx = x;
				this.sy = this.my = y;
				this.downTime = new Date().getTime();
				this.isMove = true;
				this.isAnim = 0;
			},
			onMove(e){
				const {isMove,isMove2,isScrollMove, mx,my, sx,sy, conf:{dir = 'hori'}} = this;
				if(!isMove) return;
				const [x,y] = this.$base.coordinate.getMouseXYByBox({e,tg: this.$el});
				this.mx = x;
				this.my = y;
				if( (dir === 'hori' ? Math.abs(sy - y) > 9 : Math.abs(sx - x) > 9 ) && !isMove2 && !isScrollMove) this.isScrollMove = true
				if(this.isScrollMove) return;
				this.isMove2 = 1;
				
				e.cancelable && e.preventDefault();
				
				const [ox,oy] = [x - mx, y - my];
				this.offset += dir === 'hori' ? ox : oy;
				(dir === 'hori' ? Math.abs(sx - x) > 9 : Math.abs(sy - y) > 9 ) && (this.isNoClick = 1);
			},
			async onUp(e){
				await new Promise(r=> setTimeout(r,1));
				let {conf:{list,dir = 'hori'}, sx,sy, isMove,downTime,isNoClick, mx,my, clickCb,realList,realCur,offset,itemSize} = this;
				this.isAnim = 1;
				
				if(!isNoClick){
					this.offset = -realCur * itemSize;
					clickCb && clickCb();
					this.clickCb = null;
				}
				
				if(!isMove) return;
				this.isMove = 0;
				
				if(!isNoClick) return;
				
				if( new Date().getTime() - downTime < 300 ) realCur += ( (dir === 'hori' ? sx - mx : sy - my)  > 0 ? 1 : -1);
				else realCur = Math.round(offset / (realList.length * itemSize) * realList.length) * -1;
					
				
				const max = realList.length - 1;
				realCur = realCur < 0 ? 0 : realCur > max ? max : realCur;
				
				this.offset = -realCur * itemSize;
				this.conf.cur = realList[realCur]?._swiperIdx;
				this.realCur = realCur;
				this.isNoClick = 0;
			},
			
			
			...tool({ keyMap:{ mergeConf:1} }),
			
		}
		
		
	}
	
</script>