<style lang="scss" scoped>
:deep() .el-dialog__header {
    display: none;
}
:deep().el-dialog__wrapper {
    backdrop-filter: blur(10px);
}

:deep().el-dialog__body {
    background-color: transparent;
    padding: 0;
    display: flex;
    justify-content: center;
    
    .content {
        width: 6.33rem;
        height: 9.6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        // background: linear-gradient(180deg, #1E4A46 0%, #0D1C24 100%);
        background: #262626;
        .head {
            width: 100%;
            height: 0.84rem;
            position: relative;
            background: url('@/assets/img/h52/92.png') no-repeat center/ 100% 100%;
            .close{
                width:  0.84rem;
                height: 0.84rem;
                position: absolute;
                right: 0;
            }
            .user {
                width: 3.22rem;
                height: 3.22rem;
                position: absolute;
                left: -0.2rem;
                top: -1.8rem;
            }
        }
        .head2{
            background: url('@/assets/img/h52/94.png') no-repeat center/ 100% 100%;

        }
        .des-area {
            width: 5.67rem;
            height: 4.67rem;
            // background: linear-gradient(180deg, rgb(39,77,75) 0%, rgb(54,77,80) 100%);
            background: linear-gradient( 180deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.15)100%);
            overflow-y: scroll;
            margin: 0.35rem 0.22rem;
            padding: 0.22rem;

            .des {
                opacity: 1;
                font-weight: 500;
                font-size: 0.28rem;
                color: #FFFFFF;
            }
        }
        .des-area2{
            // background: linear-gradient(180deg, rgb(39,83,143) 0%, rgb(54,74,109) 100%);

        }
        .tip {
            width: 5.91rem;
            height: 0.97rem;
            // background: url('@/assets/img/h5/299.png') no-repeat center/ 100% 100%;
            display: flex;
            line-height: 1.3rem;
            // justify-content: center;
            font-size: 0.32rem;
            color: #EBD9AD;
            line-height: 0.45rem;
            text-align: left;
            font-style: normal;
        }
        .tip2{
            // background: url('@/assets/img/h5/309.png') no-repeat center/ 100% 100%;
            // color: #92bcd3;
        }
        .footer {
            width: 100%;
            margin-top: 0.34rem;
            margin-bottom: 0.34rem;
            // height: 1.17rem;
            // background: url('@/assets/img/h5/303.png') no-repeat center/ 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .wait-receive {
                width: 5.82rem;
                height: 0.82rem;
                background: url('@/assets/img/h52/95.png') no-repeat center/ 100% 100%;

            }

            .has-receive {
                width: 5.82rem;
                height: 0.82rem;
                background: url('@/assets/img/h52/96.png') no-repeat center/ 100% 100%;

            }
        }
        .footer2{
            // background: url('@/assets/img/h5/311.png') no-repeat center/ 100% 100%;

        }
    }
    .content2{
        // background: linear-gradient( 180deg, #1E5398 0%, #0D0F24 100%);
    }
    .amount-list {
        @include n-row1;
        flex-wrap: wrap;
        gap: 0.3rem;
        .amount-item-cur {
            border: 0.02rem solid #FFAD00;
            background: #1F1D1C;
        }
        >li {
            @include n-col2;
            align-items: center;
            background: #141312;
            border-radius: 0.12rem;
            position: relative;
            width: 2.74rem;
            height: 1.12rem;
            .select-img {
                position: absolute;
                width: 0.38rem;
                right: 0;
                top: 0;
            }
            div:first-child {
                font-weight: 500;
                font-size: 0.28rem;
                color: #FFB416;
                line-height: 0.38rem;
                display: flex;
                align-items: center;
                justify-items: center;
                >img {
                    width: 0.26rem;
                    margin-right: 4px;
                }
            }
            div:nth-child(2) {
                margin-top: 4px;
                line-height: 0.28rem;
                font-weight: 500;
                font-size: 0.2rem;
                color: #9A865D;
            }
        }
    }
}
</style>

<template>

    <el-dialog z-index="100" width="6.33rem" :visible.sync="welfareConf.show" append-to-body>
		<img class="h-dialog-close" src="@/assets/img/h52/close.png" @click="welfareConf.show = false"/>
        <div :class="['content',welfareConf.type == 'bg'?'content2':'']">
            <div :class="['head',welfareConf.type == 'bg'?'head2':'']">
                <div @click="welfareConf.show = false" class="close"></div>
                <img v-if="welfareConf.type == 'xf'" class="user" src="@/assets/img/h52/91.png" alt="">
                <img v-else class="user" src="@/assets/img/h52/93.png" alt="">
            </div>
            <div :class="['des-area',welfareConf.type == 'bg'? 'des-area2':'']">
                <div v-if="welfareConf.type == 'bg'" class="des">
1. 当日充值金额500以上，回血白给金额价值10%紫币或15%金币<br><br>
2. 当日充值金额2000以上，回血白给金额价值15%紫币或20%金币<br><br>
3. 当日充值金额5000以上，回血白给金额价值20%紫币或25%金币<br><br>
4. 规则:[当日总消费－当日总所得（白给福利的所得除外） = 白给金额]当日充值消费后，账户蓝钻余额+紫钻余额+背包内饰品总价值少于当日充值金额 即可满足白给金额次日回血条件（紫钻可在商城直接购买饰品皮肤）<br><br>
*领取方式：次日点击对应按钮领取，当日不可领取，次日未领取第三日不可补领。
                </div>
                <div v-else class="des">
                    1.当日流水满200流水奖励0.5%紫币或者1%金币<br><br>
2. 当日流水满3000流水奖励1%紫币或者1.5%金币<br><br>
3. 当日流水满10000流水奖励1.5%紫币或者2%金币<br><br>
*领取方式：次日点击对应按钮领取，当日不可领取， 次日未领取第三日不可补领，紫钻可在商城直接购买饰品皮肤。
                </div>
            </div>
            <div :class="['tip',welfareConf.type == 'bg'?'tip2':'']">
                请选择领取方式
            </div>
            <ul class="amount-list">
                <li v-for="item in list" :class="{'amount-item-cur': item.key === current} " :key="item.key"
                    @click="changeMode(item)">
                    <div><img :src="item.img" alt="">{{ item.num }}</div>
                    <img v-if="current === item.key" class="select-img"src="@/assets/img/h52/34.png" />
                </li>
            </ul>
            <div :class="['footer',welfareConf.type == 'bg'?'footer2':'']">
                <div @click="receive" v-if="obj && obj.receive&& ((current ==0&&obj.currentlyAvailable1 !=0 )||(current ==1&& obj.currentlyAvailable2 !=0) ) " class="wait-receive"></div>
                <div v-else class="has-receive"></div>
            </div>
        </div>
    </el-dialog>

</template>

<script>
const defaults = () => ({
    type: '',
    show: false
})
export default {
    name: 'h-welfare-dialog',
    props: {
        welfareConf: {
            type: Object,
            default: defaults
        },
    },
    data() {
        return {
            current: 0,
            obj:{},
            list: [
                {
                    img: require('@/assets/img/h52/26copy.png'),
                    key: 0,
                    num: '0'
                },
                {
                    img: require('@/assets/img/h52/75.png'),
                    key: 1,
                    num: '0'
                },
            ]
        }
    },
    watch: {
        welfareConf: {
            immediate: true,
            deep: true,
            handler(conf) {
                console.log(conf)
                if (conf) {
                    this.welfareConf = conf
                    this.getData()
                };
            }
        }
    },
    methods: {
        changeMode(item) {
            this.current = item.key
        },
       async receive(){
         let res = await this._req(this.$api.immediatelyReceive({
            welfareType: this.welfareConf.type == 'xf' ? 1 : 2,
                    claimType: this.current == 0? 1 : 2
          }))
          if(!res) return 
          this.getData()
          this.$hMsg('领取成功~');
            this.$store.dispatch('getInfo')
        },
        getData(){
            this._req(this.$api.getwelfare({
                        welfareType: this.welfareConf.type == 'xf' ? 1 : 2
                    })).then(res=>{
                        this.obj = res.data
                        this.$set(this.list[0], 'num', res.data.currentlyAvailable1 )
                        this.$set(this.list[1], 'num', res.data.currentlyAvailable2)
                    })
        }
    },
    computed:{
			isLogin(){
				return this.$store.state.token
			},
			userInfo(){
				return this.$store.state.userInfo || {}
			}
		},
}

</script>
