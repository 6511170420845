/*
 * @Author: wilson
 * @Date: 2024-03-11 11:19:05
 * @LastEditTime: 2024-03-25 11:18:16
 * @LastEditors: wilson
 * @Description: 
 */
import {req} from '@/utils/tool'


export default {
	// web_福利详情接口
	getwelfare:params => req({ url: `/cs/benefits/dailyActivities`,  method: 'get',  params  }),
	immediatelyReceive: data => req({ url: `/cs/benefits/immediatelyReceive`,  method: 'post',  data  }),
	// 会员盲盒列表
	getvipboxList: params => req({ url: `/cs/box/common/vipboxList`,  method: 'get',  params  }),
	getCheckSteamID: params => req({ url: `/cs/user/checkTradeUrl`,  method: 'get',  params  }),
	getKkingsCaurel: params => req({ url: `/cs/backpack/common/carouselListTop3`,  method: 'get',  params  }),
	getEveryDayOpenBoxRank: params => req({ url: `/cs/battleinfo/battleRanking`,  method: 'get',  params  }),
	getPrimeRank: () => req({ url: `/cs/battleinfo/prime_ranking`,  method: 'get'  }),

	//换绑邀请码
	api_setInviteCode: params => req({ url: `/cs/user/updateInvitationCode/${params.code}`,  method: 'get',  params  }),
	getCodeUser: params => req({ url: `/cs/user/common/sendNode`,  method: 'get',  params  }),
	updatePwdUser: params => req({ url: `/cs/user/common/retrievePassword`,  method: 'get',  params  }),
	updateNameUser: params => req({ url: `/cs/user/updateUserName`,  method: 'get',  params  }),
	updateUrlUser: params => req({ url: `/cs/user/updateUserUrl`,  method: 'get',  params  }),
	uplevelRecordUser: params => req({ url: `/cs/user/myGoodsUpgrateRecord`,  method: 'get',  params  }),
	getInfoUser: params => req({ url: `/cs/user/getInfo`,  method: 'get',  params  }),
	regUser: data => req({ url: `/cs/user/common/register`,  method: 'post',  data  }),
	loginUser: params => req({ url: `/cs/user/common/login`,  method: 'get',  params  }),
	codeLoginUser: params => req({ url: `/cs/user/common/codeLogin`,  method: 'get',  params  }),
	updateAvatarUser: data => req({ url: `/cs/user/avatar`,  method: 'post',  data  }),
	dashPromotionUser: params => req({ url: `/cs/user/promotion_detail`,  method: 'get',  params  }),
	incomPromotionUser: data => req({ url: `/cs/usersy/list`,  method: 'post',  data  }),
	billUser: data => req({ url: `/cs/recharge/getPersonalBill`,  method: 'post',  data  }),
	realNameUser: params => req({ url: `/cs/user/authentication`,  method: 'get',  params  }),
	cdkUser: params => req({ url: `/buff/redbag/receiveRedbag`,  method: 'get',  params  }),
	
	
	
	getInfoVip: params => req({ url: `/cs/vipgrade/common/myVipInfo`,  method: 'get',  params  }),
	receiveVip: params => req({ url: `/cs/vipgrade/receive`,  method: 'get',  params  }),
	
	
	getTask: data => req({ url: `/cs/task/common/taskList`,  method: 'post',  data  }),
	receiveTask: params => req({ url: `/cs/task/taskReceive`,  method: 'get',  params  }),
	
	
	getActivity: params => req({ url: `/cs/banner/common/list`, noMsg:1, method: 'get',  params  }),

	
	
	getKnapsack: data => req({ url: `/cs/backpack/myBackPack`, method: 'post',  data  }),
	retrieveKnapsack: data => req({ url: `/cs/backpack/batchRetrieve`, method: 'post',  data  }),
	getRetrieveKnapsack: data => req({ url: `/cs/backpack/myRetrieveList`, method: 'post',  data  }),
	exchangeKnapsack: data => req({ url: `/cs/backpack/exchangeGoogs`, method: 'post',  data  }),
	
	
	getConfPay: params => req({ url: `/system/dict/data/common/commonCZDictType`, method: 'get',  params  }),
	createPay: params => req({ url: `/cs/recharge/rechargeSomisspay`, method: 'get',  params  }),
	getResPay: params => req({ url: `/cs/recharge/getInfo`, method: 'get',  params  }),
	CreateH5Pay: params => req({ url: `/cs/recharge/rechargeSomisspayH5`, method: 'get',  params  }),
	CreateNewPay: data => req({ url: `/cs/recharge/rechargeV2`, method: 'post',  data  }),
	
	
	
	getPropUplevel: data => req({ url: `/cs/goods/common/luckList`, method: 'post',  data  }),
	recentlyUplevel: params => req({ url: `/cs/upgrate/common/allRecently`, method: 'get',  params  }),
	myRecentlyUplevel: params => req({ url: `/cs/upgrate/myRecently`, method: 'get',  params  }),
	getRangeUplevel: params => req({ url: `/cs/upgrate/common/upgrateRanking`, method: 'get',  params  }),
	startUplevel: data => req({ url: `/cs/goods/luckyGoodsUpgrate`, method: 'post',  data  }),
	
	
	
	getStore: data => req({ url: `/cs/goods/common/shoppingMallList`, method: 'post',  data  }),
	buyStore: data => req({ url: `/cs/goods/shoppingBuy`, method: 'post',  data  }),
	
	
	
	getPropBox: params => req({ url: `/cs/box/common/goodsListByBoxId`, method: 'get',  params  }),
	openBox: params => req({ url: `/cs/box/unpacking`, method: 'get',  params  }),
	getRecordBox: params => req({ url: `/cs/backpack/common/backPackopenBoxTopForty`, method: 'get',  params  }),
	getBattleBox: params => req({ url: `/cs/box/battleBoxList`, method: 'get',  params  }),
	getTitleBox: params => req({ url: `/cs/boxtitle/common/list`, method: 'get',  params  }),
	
	
	
	getRoomBattle: params => req({ url: `/cs/battleinfo/battleList`, method: 'get',  params  }),
	getRoomBattleBest: () => req({ url: `/cs/battleinfo/best_ranking`, method: 'get'  }),
	getTopBattle: params => req({ url: `/cs/battleinfo/battleRanking`, method: 'get',  params  }),
	readBattle: params => req({ url: `/cs/battleinfo/battleInfo/${params.id}`, method: 'get',  params  }),
	cancelBattle: data => req({ url: `/cs/battleinfo/cancel_battle`, method: 'post',  data  }),
	createBattle: data => req({ url: `/cs/battleinfo/createBattle`, method: 'post',  data  }),
	startBattle: data => req({ url: `/cs/battleinfo/startBattle`, method: 'put',  data  }),
	getBotBattle: params => req({ url: `/cs/battleinfo/robatBattleInfoList`, method: 'get',  params  }),
	
	
	getRoll: data => req({ url: `/cs/roll/common/pageList`, method: 'post',  data  }),
	getMyRoll: data => req({ url: `/cs/roll/partakePageList`, method: 'post',  data  }),
	readRoll: params => req({ url: `/cs/roll/common/getRollInfo`, method: 'get',  params  }),
	joinRoll: params => req({ url: `/cs/roll/joinRoll`, method: 'get',  params  }),
	
	
	
	getBannerHome: params => req({ url: `/cs/movable/common/bannerList`, method: 'get',  params  }),
	getBoxHome: params => req({ url: `/cs/box/common/boxList`, method: 'get',  params  }),
	getRollHome: params => req({ url: `/cs/roll/common/rollHomePageDisplay`, method: 'get',  params  }),
	getNoticeHome: params => req({ url: `/cs/api/common/notice`, method: 'get',  params  }),
	getBoxTurnHome: params => req({ url: `/cs/backpack/common/carouselList`, method: 'get',  params  }),

	// 幸运掉落列表
	getLuckyList: params => req({ url: `/cs/lucky/list`, method: 'get',  params  }),

	// 欧皇掉落
	getLuckyHeadDropList: params => req({ url: `/cs/lucky/headDropList`, method: 'get',  params  }),
	getLuckyMyDropList: params => req({ url: `/cs/lucky/myDropList`, method: 'get',  params  }),


	
	
}
