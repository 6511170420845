
<template>
	
	<ul class="h-radioBtns">
		<li :class="{'h-radioBtns-item':1, 'h-radioBtns-cur': value === item.value}" v-for="item in list" :key="item.value" @click="$emit('input',item.value)" >
			<slot :data="item"></slot>
			{{item.label}}
		</li>
	</ul>
</template>

<script>
	
	export default{
		name: 'h-radio-btns',
		props: {
			list:{
				type: Array,
				default:()=>[]
			},
			value: [String,Number],
		},
		data(){
			return{
				
			}
		},
		mounted(){
			
		},
		methods:{
		}
		
		
	}
	
</script>
