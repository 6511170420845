<style lang="scss" scoped>
	
	
	.h-radar{
		width: 100%;
		height: 100%;
		position: relative;
		>canvas{
			width: 100%;
			height: 100%;
		}
		.h-radar-prog{
			@include n-center;
			font-size: 0.42rem;
			color: rgba(155, 255, 221, 1);
			padding-left: 0.2rem;
			font-family: AvenirNext, AvenirNext;
			font-weight: 800;
			font-size: 0.36rem;
			color: #FFB51B;
		}
		.h-radar-bg{
			width: 100%;
		}
	}
</style>
<template>
	<div class="h-radar" >
		<canvas ref="c" :width="w * dpr" :height="h * dpr"></canvas>
		<!-- <div class="h-radar-prog">{{ ~~(prog * 100) }}<i>%</i></div> -->
		<div class="h-radar-prog">{{ (prog * 100).toFixed(2) }}<i>%</i></div>

	</div>
</template>

<script>
export default {
	name: 'h-radar',
	props:{
		dpr:{
			type: Number,
			default:2
		},
		min:{
			type:Number,
			default:0,
		},
		max:{
			type:Number,
			default:100,
		},
		val:{
			type:Number,
			default:0,
		},
		offset:{
			type:Number,
			default:0,
		},
		slideVal:{
			type:[Number,Boolean],
			default:0,
		},
		
	},
    data(){
        return {
			w:0,
			h:0,
        }
    },
	computed:{
		prog(){
			let {val,max,min} = this;
			val = val < min ? min : val > max ? max : val;
			return (val - min) / (max - min);
		}
	},
    mounted(){
		this.c = this.$refs.c;
		this.ctx = this.c.getContext('2d');
		
		this.arrowImg = new Image();
		this.arrowImg.src = require('@/assets/img/h52/102.png')
		
		// this.arrowImg2 = new Image();
		// this.arrowImg2.src = require('@/assets/img/h52/103.png')

		this.planeImg = new Image();
		this.planeImg.src = require('@/assets/img/h52/101copy.png')
		
		this.resize();
		this.loop();
    },
    methods: {
		resize(){
			if(!this._isMounted || this._isDestroyed) return;
			const {c, dpr} = this;
			this.w = c.clientWidth;
			this.h = c.clientHeight;
		},
		toRadian(angle) {
			return Math.PI / 180 * angle;
		},
		// 绘制扇形
		drawPie(ctx,r1,a1,a2){
			//创建渐变对象
			let [x,y] = this.$base.coordinate.polarToCartesian(a1, r1, 1);
			let [x2,y2] = this.$base.coordinate.polarToCartesian(a2, r1, 1);
			const gradient = ctx.createLinearGradient(x, y, x2, y2);
			gradient.addColorStop(0, 'rgba(255, 173, 0, 0.3)'); // 黄色
			gradient.addColorStop(1, 'rgba(255, 112, 0, 0.3)'); // 橙色
			ctx.fillStyle = gradient;

			ctx.beginPath();
			ctx.moveTo(0, 0);
			ctx.arc(0, 0, r1, a1, a2 );
			ctx.fill();

		},
		drawArc(ctx,r1,r2,a1,a2){
			//创建渐变对象
			let [x,y] = this.$base.coordinate.polarToCartesian(a1, r1, 1);
			let [x2,y2] = this.$base.coordinate.polarToCartesian(a2, r1, 1);
			const gradient = ctx.createLinearGradient(x, y, x2, y2);
			gradient.addColorStop(0, 'rgba(255, 173, 0, 1)'); // 黄色
			gradient.addColorStop(1, 'rgba(255, 112, 0, 1)'); // 橙色
			ctx.fillStyle = gradient;

			ctx.beginPath();
			ctx.arc(0,0,r1, a1, a2);
			ctx.lineTo(...this.$base.coordinate.polarToCartesian( a2,r1,1))
			ctx.lineTo(...this.$base.coordinate.polarToCartesian( a2,r2,1))
			ctx.arc(0,0, r2, a2, a1, true)
			ctx.lineTo(...this.$base.coordinate.polarToCartesian( a1,r1,1))
			ctx.lineTo(...this.$base.coordinate.polarToCartesian( a1,r2,1))
		},
		
		draw(){
			let {ctx,w,h,bg,dpr,prog,max,min,val,offset,slideVal,arrowImg,planeImg} = this;
			const cx = w / 2, cy = h / 2, r = 0.39 * w, progSize = 0.025 * w, pi = Math.PI,progGap = max - min;
			// console.log('===offset', offset)
			offset = (((offset - min) / progGap + min) * pi * 2) || 0;
			slideVal =  (slideVal % 360 / 360 * pi * 2) || 0;
			val = val < min ? min : val > max ? max : val;
			val-=min;
			
			ctx.save();
			ctx.scale(dpr,dpr);
			ctx.clearRect(0,0,w,h);
			
			
			ctx.save();
			
			ctx.translate(cx,cy);
			
			
			
			
			
			// 边框
			ctx.lineWidth = progSize * 1.2;
			ctx.beginPath();
			ctx.strokeStyle = '#394048';
			ctx.fillStyle = 'rgb(8,12,18)';
			ctx.arc(0,0,r + progSize * 0.5,0, 2 * pi);
			ctx.fill();
			ctx.stroke();
			
			(()=>{
				const size = r * 1.9;
				ctx.drawImage(planeImg,-size / 2, -size / 2, size, size);
			})();
			
			
			ctx.lineWidth = 1;
			ctx.fillStyle = '#FF7000';
			this.drawArc(ctx,r,r+progSize, pi * -0.5 + offset , pi * -0.5 + offset +  pi * 2 * (val / progGap)   );
			ctx.fill();

			// 扇形开始
			this.drawPie(ctx,r, pi * -0.5 + offset, pi * -0.5 + offset +  pi * 2 * (val / progGap));
			
			
			(()=>{
				// 两端圆圈
				// const size =  0.06 * r;
				// ctx.lineWidth = 0.02 * r;
				// ctx.fillStyle = 'linear-gradient( 90deg, #FF7000 0%, #FFAD00 100%)';
				// ctx.strokeStyle = '#fff';
				
				// let [x,y] = this.$base.coordinate.polarToCartesian( pi * -0.5 + offset, r +  progSize / 2,1);
				// ctx.beginPath()
				// ctx.arc(x,y,size,0, pi * 2);
				// ctx.fill();
				// ctx.stroke();
				
				// ([x,y] = this.$base.coordinate.polarToCartesian( pi * -0.5 + offset +  pi * 2 * (val / progGap), r+progSize / 2,1));
				// ctx.beginPath()
				// ctx.arc(x,y,size,0, pi * 2);
				// ctx.fill();
				// ctx.stroke();
				
				
			})();
			
			(()=>{
				//指针
				// const w = r * 0.1, h = w * 3.76;
				// const [x,y] = this.$base.coordinate.polarToCartesian( pi * -0.5, h + 0.47 * r,1);
				// ctx.save();
				// ctx.rotate( offset +  pi * 2 * (val / progGap))
				// ctx.drawImage(arrowImg,x -w / 2,y,w,h)
				// ctx.restore();
				
			})();
			
			
			(()=>{
				// 箭头
				// console.log('箭头', slideVal)
				ctx.save();
				ctx.rotate( slideVal )
				const size =  6;
				const [x,y] = this.$base.coordinate.polarToCartesian( -0.5 * pi, r + progSize + r * 0.1,1);
				ctx.strokeStyle = '#FF7000';
				ctx.beginPath()
				ctx.moveTo(x + size, y - size);
				ctx.lineTo(x,y);
				ctx.lineTo(x - size,y - size);
				ctx.lineWidth = size;
				ctx.stroke()
				ctx.restore();
			})();
			
			
			
			
			ctx.restore();
			
			
			ctx.restore();
		},
		loop(){
			if(this._isDestroyed) return
			this.draw();
			window.requestAnimationFrame(this.loop)
			
		},
    }
}
</script>