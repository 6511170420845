
<template>
	<div class="h-search">
		<input v-model="form[conf.k]" v-bind="conf.props" v-on="conf.on" @input="$emit('input')" />
		<img v-if="!conf.hideIcon" class="h-input-after" @click="$emit('search',{vm, v:form[conf.k]})" :src="conf.btn"  />
	</div>
	
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		props:{
			placeholder:'请输入关键字搜索'
		},
		on:{},
		k: 'v',
		btn: require('@/assets/img/h52/39.png'),
		hideIcon: false
	});
	export default{
		name: 'h-search',
		props: {
			form:{
				type: Object,
				default: ()=>({})
			},
			conf:{
				type: Object,
				default:defConf
			},
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			}
		},
		data(){
			return{
				vm:this,
			}
		},
		mounted(){
			
		},
		methods:{
			...tool({ keyMap:{ mergeConf:1} }),
		}
		
		
	}
	
</script>
