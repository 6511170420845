 

<template>
	<div class=" n-drawer h-drawer" :class="{'n-drawerShow': conf.show}">
		<div class="n-drawer-mask" @click="conf.maskClose && (conf.show = false)"></div>
		<div class="n-drawer-content">
			<!-- <header class="h-head h-drawer-head">{{conf.title}}<i class="n-icon-close"  @click="conf.show = false"></i></header> -->
			<img class="h-dialog-close" src="@/assets/img/h52/close.png" @click="conf.show = false"/>
			<article class="h-drawer-main n-hideScroll"><slot /></article> 
		</div>
	</div>
	
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		maskClose: true,
		show:false,
		title: '',
	});
	export default{
		name: 'h-drawer',
		props: {
			conf:{
				type: Object,
				default:defConf
			},
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			}
		},
		data(){
			return{
				
			}
		},
		mounted(){
			
		},
		methods:{
			...tool({ keyMap:{ mergeConf:1} }),
		}
		
		
	}
	
</script>
