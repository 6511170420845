 

<template>
	<div class=" n-dialog h-dialog" :class="{'n-dialogShow': conf.show}">
		<div v-if="!hideMask" class="n-dialog-mask" @click="conf.maskClose && (conf.show = false)"></div>
		<div class="n-dialog-content" v-if="!customContent">
			<!-- <header class="h-dialog-head"><img  src="@/assets/img/h5/24.png" /></header> -->
			<div class="h-title" v-if="conf.title">
				<div>{{ conf.title }}</div>
				<img src="@/assets/img/h52/close2.png" @click="conf.show = false"/>
			</div>
			<slot /> 
		</div>
		<slot v-else />
	</div>
	
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		maskClose: true,
		show:false
	});
	export default{
		name: 'h-dialog',
		props: {
			conf:{
				type: Object,
				default:defConf
			},
			customContent: {
				type: Boolean,
				default: false
			},
			hideMask: {
				type: Boolean,
				default: false
			}
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			}
		},
		data(){
			return{
				
			}
		},
		mounted(){
			
		},
		methods:{
			...tool({ keyMap:{ mergeConf:1} }),
		}
		
		
	}
	
</script>
