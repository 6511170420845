
<style lang="scss" scoped>
	.n-slide{
		width: 100%;
		background: url('@/assets/img/h52/80.png') no-repeat center center / 100% 100%;
		height: 0.74rem;
		@include n-row1;
		padding: 0 20px;
		div {
			width: 100%;
			height: 0.12rem;
			background: rgba(55, 55, 66, 1);
			position: relative;
		}
		div>em{
			height: 0.12rem;
			background: linear-gradient( 90deg, #FFAD00 0%, #FFAD00 100%);
			position: absolute;
			top: 0;
			display: inline-block;
			>i{
				cursor: pointer;
				width: 0.3rem;
				right: 0;
				top: 50%;
				position: absolute;
				transform: translate(50%,-50%);
				height: 0.3rem;
				background: url('@/assets/img/h52/103.png') no-repeat center center / 100% 100%;
			}
		}
	}
</style>
<template>
	<div class="n-slide">
		<div>
			<em :style="{'width': offset + 'px'}"><i @mousedown="onDown" @touchstart="onDown"></i></em>
		</div>
	</div>
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		k: 'prog',
		min:0,
		max:100,
	});
	export default{
		name: 'n-slide',
		props: {
			form:{
				type: Object,
				default: ()=>({})
			},
			conf:{
				type: Object,
				default:defConf
			},
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			},
			
		},
		computed:{
			offset:{
				get(){
					const {form,conf:{k,min,max},w} = this;
					let v = form[k];
					v = v < min ? min : v > max ? max : v;
					v -= min;
					return  v / (max - min) * w;
				},
				set(v){
					const {form,conf:{k,min,max},w} = this;
					v = v < 0 ? 0 : v > w ? w : v;
					v = (v / w) * (max - min) + min;
					if(form[k] !== v) form[k] = v;
				}
				
			},
		},
		data(){
			
			return{
				id: this.$base.uuid(),
				isMove:false,
				isScrollMove:false,
				isMove2:false,
				sx: 0,
				sy: 0,
				mx: 0,
				my: 0,
				w:0,
			}
		},
		mounted(){
			window.c = this;
			this.resize();
			window.addEventListener('resize', this.resize);
			document.addEventListener('mousemove',this.onMove);
			document.addEventListener('touchmove',this.onMove, {passive:false});
			document.addEventListener('mouseup',this.onUp);
			document.addEventListener('touchend',this.onUp);
		
		},
		beforeUnmount(){
			window.removeEventListener('resize', this.resize);
			document.removeEventListener('mousemove',this.onMove);
			document.removeEventListener('touchmove',this.onMove);
			document.removeEventListener('mouseup',this.onUp);
			document.removeEventListener('touchend',this.onUp);
		},
		
		methods:{
			resize(){
				this.w = this.$el.clientWidth - 40; // 设置的padding
			},
			
			onDown(e){
				const [x,y] = this.$base.coordinate.getMouseXYByBox({e,tg: this.$el });
				this.isScrollMove = this.isMove2 = false;
				this.sx = this.mx = x;
				this.sy = this.my = y;
				this.downTime = new Date().getTime();
				this.isMove = true;
			},
			onMove(e){
				let {isMove,isMove2,isScrollMove, offset, w, mx,sx,sy} = this;
				if(!isMove) return;
				const [x,y] = this.$base.coordinate.getMouseXYByBox({e,tg: this.$el});
				this.mx = x;
				this.my = y;
				if( Math.abs(sy - y) > 9 && !isMove2 && !isScrollMove) this.isScrollMove = true
				if(this.isScrollMove) return;
				this.isMove2 = 1;
				
				e.cancelable && e.preventDefault();
				
				offset += x - mx;
				offset = offset < 0 ? 0 : offset > w ? w : offset;
				this.offset = offset;
			},
			async onUp(e){
				this.isMove = this.isMove2 = this.isScrollMove = 0;
			},
			
			
			...tool({ keyMap:{ mergeConf:1} }),
			
		}
		
		
	}
	
</script>
