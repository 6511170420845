
<template>
	<div class="h-inputCode"  >
		<img v-if="!conf.hideIcon"  class="h-input-before" src="@/assets/img/h52/15.png" />
		<input class="h-input"  v-model="form[conf.k]" v-bind="conf.props" v-on="conf.on"  />
	</div>
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		on:{},
		k: 'userPhone',
		hideIcon: false,
	});
	export default{
		name: 'h-input',
		props: {
			form:{
				type: Object,
				default: ()=>({})
			},
			conf:{
				type: Object,
				default:defConf
			},
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			}
		},
		data(){
			return{
			}
		},
		methods:{
			...tool({ keyMap:{ mergeConf:1} }),
		}
		
		
	}
	
</script>
