/*
 * @Author: wilson
 * @Date: 2024-03-25 13:51:50
 * @LastEditTime: 2024-04-08 09:22:01
 * @LastEditors: wilson
 * @Description: 
 */
import {base} from '@/utils/tool.js'
const t = s=>s;


const enu =  new Proxy({
	bbStatus: [
		{label: '取回中', value:1},
		{label: '已取回', value:2},
		{label: '取回失败', value:4},
		{label: '待发货', value:5},
		{label: '待收货', value:6},
		{label: '待取货', value:0},
	],
	payType:[
		// {label:"支付宝", value: 2},
		{label:"支付宝", value: 4},

		// {label:"支付宝", value: 1},
		// {label:"微信支付", value: 0},
		// {label:"快捷支付", value: 3},
		
	],
	battleMode:[
		{label:"欧皇", value: 0, img: require('@/assets/img/h52/67.png')},
		{label:"非酋", value: 1, img: require('@/assets/img/h52/66.png')},
	],
	battleStatus:[
		{label:"等待中", value: 0},
		{label:"对战中", value: 1},
		{label:"已结束", value: 2},
	],
	goodsGrate: [
		{label: require('@/assets/img/h52/a/1.png'), value:0},
		{label: require('@/assets/img/h52/a/2.png'), value:1},
		{label: require('@/assets/img/h52/a/3.png'), value:2},
		{label: require('@/assets/img/h52/a/4.png'), value:3},
		{label: require('@/assets/img/h52/a/5.png'), value:4},
		{label: require('@/assets/img/h52/a/5.png'), value:5},
		{label: require('@/assets/img/h52/a/5.png'), value:6},
		{label: require('@/assets/img/h52/a/5.png'), value:7},
		{label: require('@/assets/img/h52/a/5.png'), value:8},
	],
	goodsGrate3: [
		{label: require('@/assets/img/h52/c/1.png'), value:0},
		{label: require('@/assets/img/h52/c/2.png'), value:1},
		{label: require('@/assets/img/h52/c/3.png'), value:2},
		{label: require('@/assets/img/h52/c/4.png'), value:3},
		{label: require('@/assets/img/h52/c/5.png'), value:4},
		{label: require('@/assets/img/h52/c/5.png'), value:5},
	],
	goodsGrate4: [
		{label: require('@/assets/img/h52/b/1.png'), value:0, color: 'rgba(228, 174, 57, 1)', colorBg: 'rgba(228, 174, 57, 0.78)'},
		{label: require('@/assets/img/h52/b/2.png'), value:1, color: 'rgba(228, 174, 57, 1)', colorBg: 'rgba(228, 174, 57, 0.78)'},
		{label: require('@/assets/img/h52/b/3.png'), value:2, color: 'rgba(228, 174, 57, 1)', colorBg: 'rgba(228, 174, 57, 0.78)'},
		{label: require('@/assets/img/h52/b/4.png'), value:3, color: 'rgba(228, 174, 57, 1)', colorBg: 'rgba(228, 174, 57, 0.78)'},
		{label: require('@/assets/img/h52/b/5.png'), value:4, color: 'rgba(228, 174, 57, 1)', colorBg: 'rgba(228, 174, 57, 0.78)'},
		{label: require('@/assets/img/h52/b/5.png'), value:5, color: 'rgba(228, 174, 57, 1)', colorBg: 'rgba(228, 174, 57, 0.78)'},
	],
	goodsGrate5: [
		{label: require('@/assets/img/h52/e/1.png'), value:0},
		{label: require('@/assets/img/h52/e/2.png'), value:1},
		{label: require('@/assets/img/h52/e/3.png'), value:2},
		{label: require('@/assets/img/h52/e/4.png'), value:3},
		{label: require('@/assets/img/h52/e/5.png'), value:4},
		{label: require('@/assets/img/h52/e/5.png'), value:5},
	],
	goodsGrate6: [
		{label: require('@/assets/img/h52/d/1.png'), value:0},
		{label: require('@/assets/img/h52/d/2.png'), value:1},
		{label: require('@/assets/img/h52/d/3.png'), value:2},
		{label: require('@/assets/img/h52/d/4.png'), value:3},
		{label: require('@/assets/img/h52/d/5.png'), value:4},
		{label: require('@/assets/img/h52/d/5.png'), value:5},
	],
	// goodsGrate:[
	// 	{label:require('@/assets/img/h5/224.png'), value: 0, color: 'rgba(228, 174, 57, 1)'},
	// 	{label:require('@/assets/img/h5/225.png'), value: 1, color: 'rgba(235, 75, 75, 1)'},
	// 	{label:require('@/assets/img/h5/226.png'), value: 2, color: 'rgba(136, 71, 255, 1)'},
	//  {label:require('@/assets/img/h5/227.png'), value: 3, color: 'rgba(94, 152, 217, 1)'},
	// 	{label:require('@/assets/img/h5/228.png'), value: 4, color: 'rgba(176, 195, 217, 1)'},
	// 	{label:require('@/assets/img/h5/325.png'), value: 5, color: 'rgba(176, 195, 217, 1)'},
	// 	{label:require('@/assets/img/h5/326.png'), value: 6, color: 'rgba(176, 195, 217, 1)'},
	// 	{label:require('@/assets/img/h5/327.png'), value: 7, color: 'rgba(176, 195, 217, 1)'},
	// 	{label:require('@/assets/img/h5/328.png'), value: 8, color: 'rgba(176, 195, 217, 1)'},
	// ],
	goodsGrate2:[
		// {label:require('@/assets/img/h5/325.png'), value: 0, color: 'rgba(176, 195, 217, 1)'},
		// {label:require('@/assets/img/h5/326.png'), value: 1, color: 'rgba(176, 195, 217, 1)'},
		// {label:require('@/assets/img/h5/327.png'), value: 2, color: 'rgba(176, 195, 217, 1)'},
		// {label:require('@/assets/img/h5/328.png'), value: 3, color: 'rgba(176, 195, 217, 1)'},
	],
	goodsMold:[
		{label: '全部', value:''},
		{label: '手套', value:4},
		{label: '匕首', value:0},
		{label: '手枪', value:1},
		{label: '微型冲锋枪', grid: 'span 2', value:3},
		{label: '步枪', value:2},
		{label: '散弹枪', value:6},
		{label: '其他', value:7},
		{label: '机枪', value:8},
		{label: '印花', value:9},
	],
	// hboxPriceBg: [
	// 	{label: require('@/assets/img/h5/288.png'), value:0},
	// 	{label: require('@/assets/img/h5/290.png'), value:1},
	// 	{label: require('@/assets/img/h5/289.png'), value:2},
	// 	{label: require('@/assets/img/h5/292.png'), value:3},
	// 	{label: require('@/assets/img/h5/287.png'), value:4},
	// ],
	itemsOneBg: [
		{label: require('@/assets/img/h52/g/1.png'), value:0},
		{label: require('@/assets/img/h52/g/2.png'), value:1},
		{label: require('@/assets/img/h52/g/3.png'), value:2},
		{label: require('@/assets/img/h52/g/4.png'), value:3},
		{label: require('@/assets/img/h52/g/5.png'), value:4},
	],
	itemsOneBg: [
		{label: require('@/assets/img/h52/g/1.png'), value:0},
		{label: require('@/assets/img/h52/g/2.png'), value:1},
		{label: require('@/assets/img/h52/g/3.png'), value:2},
		{label: require('@/assets/img/h52/g/4.png'), value:3},
		{label: require('@/assets/img/h52/g/5.png'), value:4},
	],
	itemsBg: [
		{label: 'linear-gradient( 180deg, #1D1815 0%, #251903 100%)', value:0},
		{label: 'linear-gradient( 180deg, #2B1A1A 0%, #190202 100%)', value:1},
		{label: 'linear-gradient( 180deg, #1A2126 0%, #241148 100%)', value:2},
		{label: 'linear-gradient( 180deg, #1A2126 0%, #06263D 100%)', value:3},
		{label: 'linear-gradient( 180deg, #222222 0%, #000102 100%)', value:4},
	],
	itemsBgBottom: [
		{label: '#E4AE39', value:0},
		{label: '#EB4B4B', value:1},
		{label: '#8847FF', value:2},
		{label: '#0C34FF', value:3},
		{label: '#FFFFFF', value:4},
	]
}, {
	get(tg,k,proxy){
		if(k === '__map') return map;
		if(k === '__mapItem') return mapItem;
		return  tg[k] ? base.clone(tg[k]).map(v=> new Proxy({...v, __label: v.label}, {
			get(tg,k){
				let v = tg[k];
				if(k === 'label') v = t(tg.__label || v);
				return v
			}
		})) : []
	}
})


const map = new Proxy({},{
	get(tg,k,v){
		tg[k] = {};
		enu[k].forEach(v=> tg[k][v.value] = v.label);
		return tg[k]
	}
})

const mapItem = new Proxy({},{
	get(tg,k,v){
		tg[k] = {};
		enu[k].forEach(v=> tg[k][v.value] = v);
		return tg[k]
	}
})

export default enu;
 

