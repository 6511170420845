
<template>
	
	<div class="h-page" >
		
		<button class="h-page-btn" @click="change(form[conf.prop.page] - 1)">上一页<i class="n-icon-ag2-right"></i></button>
		
		<template v-for="item in list">
			<span class="h-page-num" v-if="item.type === 'ellipsis'">...</span>
			<span :class="{'h-page-num':1,'h-page-cur': form[conf.prop.page] === item.value }" @click="change(item.value)" v-else>{{item.label}}</span>
		</template>
		
		<button class="h-page-btn" @click="change(form[conf.prop.page] + 1)">下一页<i class="n-icon-ag2-right"></i></button>
	</div>
	
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		prop:{ 
			page: 'pageNum',
			pageSize: 'pageSize',
			total: '_total',
		},
		autoUpdate: 1,
		pageCount: 5,
	});
	export default{
		name: 'h-page',
		props: {
			form:{
				type: Object,
				default: ()=>({})
			},
			conf:{
				type: Object,
				default:defConf
			},
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			}
		},
		data(){
			return{
				
			}
		},
		computed:{
			list(){
				let {max,conf:{pageCount,prop}, form} = this;
				let v = form[prop.page];
				v = v > max || v < 1 ? 1 : v;
				let list = [{dir: 'cur', value:v, label:v }]
				
				
				// 当前page左右2边均分的个数
				let sideNum = (pageCount - 1) / 2;
				let leftNum, rightNum;
				// 左边
				const addLeft = ()=>{
					if( v - 1 - leftNum > 0 ){
						for(let i = v - 1; leftNum > 2; i--){
							leftNum--;
							list.unshift({dir:'left',label: i, value:i})
						}
						leftNum >= 2 && list.unshift({ dir:'left', type: 'ellipsis' })
						list.unshift({ dir:'left', label: 1, value: 1 })
						leftNum = 0;
					}
					else{
						for(let i = v - 1; i > 0; i--){
							leftNum--;
							list.unshift({dir:'left', label: i, value:i})
						}
					}
				}
				// 右边
				const addRight = ()=>{
					if( v + rightNum < max ){
						for(let i = v + 1; rightNum > 2; i++){
							rightNum--;
							list.push({dir:'right',label: i, value:i})
						}
						list.push({ dir:'right', type: 'ellipsis' })
						list.push({ dir:'right', label: max, value: max })
						rightNum = 0;
					}
					else{
						for(let i = v + 1; i <= max; i++){
							list.push({dir:'right',label: i, value:i})
							rightNum--;
						}
					}
				}
				
				
				// 按钮个数不够，只计算一边
				if(pageCount < 7){
					leftNum = 1;
					addLeft();
					
					rightNum = pageCount - 2 + leftNum;
					addRight();
					sideNum = 1;
				}
				else{
					// 左边可加的个数
					leftNum = sideNum;
					addLeft();
								
					// 右边要加上左边剩下的个数
					rightNum = sideNum + leftNum;
					addRight();
					
				}
				
				
				
				// 右边加完还有剩，看左边还要不要
				if(rightNum > 0 && !leftNum ){
					list = list.filter(v=> v.dir !== 'left');
					leftNum = sideNum + rightNum;
					addLeft();
				}
				
				
				return list
			},
			max(){
				const {conf:{prop},form} = this;
				return Math.ceil(form[prop.total] / form[prop.pageSize]);
			}
		},
		mounted(){
			
		},
		methods:{
			change(v){
				const {conf:{prop, autoUpdate}, max,form} = this;
				v = v > max ? max : v < 1 ? 1 : v;
				if(v === form[prop.page] || !autoUpdate || this.$base.isType(autoUpdate) === 'function' && !autoUpdate({vm:this,v,conf:this.conf, form:this.form})) return;
				form[prop.page] = v;
				this.$emit('change',{vm:this,conf:this.conf})
			},
			...tool({ keyMap:{ mergeConf:1} }),
		}
		
		
	}
	
</script>



