import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index.js'
Vue.use(Router);
 
['go','replace','push'].forEach(k=> {
	const func = Router.prototype[k];
	Router.prototype[k] = function(){ 
		const r = func.call(this,...arguments);
		return r && r.catch(e=> e)
	}
} )



export const getCacheKey = r => {
	const {meta,path,query} = r;
	if(!meta) return path;

	const {noCache, cacheK,queryCacheK} = meta;
	if(noCache) return false;
	if(cacheK) return cacheK;
	if(queryCacheK) return query[queryCacheK] || path;
	return path;
}
export const getTitle = r => {
	if(!r.meta) return ''
	
	if(r.query && r.meta.queryTitleK && r.query[r.meta.queryTitleK]) return r.query[r.meta.queryTitleK]
	return  r.meta.navTitle ? r.meta.navTitle(r) : r.meta.navTitle || r.meta.title;
};




const router = new Router({
	base: './',
	routes: [
		{
			path: "/h5",
			redirect: "/h5/home",
			component: () => import( "@/views/h5/layout.vue"),
			children: [
				{ path: "/h5/home", meta: { title: "首页"  }, component: () => import( "@/views/h5/home") },
				{ path: "/h5/me", meta: { title: "个人中心"  }, component: () => import( "@/views/h5/me") },
				{ path: "/h5/knapsack", meta: { title: "我的背包"  }, component: () => import( "@/views/h5/knapsack") },
				{ path: "/h5/recharge", meta: { title: "充值"  }, component: () => import( "@/views/h5/recharge") },
				
				{ path: "/h5/uplevel", meta: { title: "升级"  }, component: () => import( "@/views/h5/uplevel") },
				{ path: "/h5/battle", meta: { title: "对战"  }, component: () => import( "@/views/h5/battle/index") },
				{ path: "/h5/battle/create", meta: { title: "创建对战"  }, component: () => import( "@/views/h5/battle/create") },
				{ path: "/h5/battle/start", meta: { title: "对战中"  }, component: () => import( "@/views/h5/battle/start") },
				
				{ path: "/h5/box", meta: { title: "开箱"  }, component: () => import( "@/views/h5/box") },
				{ path: "/h5/vipBox", meta: { title: "会员盲盒"  }, component: () => import( "@/views/h5/vipBox") },
				{ path: "/h5/luckyDrop", meta: { title: "幸运掉落"  }, component: () => import( "@/views/h5/luckyDrop") },
				
				
				{ path: "/h5/roll", meta: { title: "Roll房"  }, component: () => import( "@/views/h5/roll/index") },
				{ path: "/h5/roll/detail", meta: { title: "Roll房详情"  }, component: () => import( "@/views/h5/roll/detail") },
				{ path: "/h5/store", meta: { title: "商城"  }, component: () => import( "@/views/h5/store") },
				{ path: "/h5/vip", meta: { title: "会员"  }, component: () => import( "@/views/h5/vip") },
				{ path: "/h5/activity", meta: { title: "热门活动"  }, component: () => import( "@/views/h5/activity") },
				{ path: "/h5/task", meta: { title: "任务中心"  }, component: () => import( "@/views/h5/task") },
				
				
				{ path: "/h5/agree/user", meta: { title: "用户协议"  }, component: () => import( "@/views/h5/agree/user") },
				{ path: "/h5/agree/privacy", meta: { title: "隐私政策"  }, component: () => import( "@/views/h5/agree/privacy") },
				{ path: "/h5/agree/about", meta: { title: "关于我们"  }, component: () => import( "@/views/h5/agree/about") },
				{ path: "/h5/agree/question", meta: { title: "常见问题"  }, component: () => import( "@/views/h5/agree/question") },
			],
		}, 
		{ 
			path: '/', 
			redirect: '/h5'
		},
	]
})


router.beforeEach(async (to, from, next) => {
	document.title = to.meta.title || '';
	
	if( ['/h5/login','/h5/home'].includes(to.path) ) return next()
	
	// if(!store.state.token ) return next(`/h5/home?_navLogin=${new Date().getTime()}`) 
	
	next();
	
	
})
export default router;