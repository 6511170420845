<style lang="scss" scoped>
	.h-realName {
		::v-deep .n-dialog-content {
			position: absolute;
			bottom: 0;
			width: 100%;
		}
	}
	.h-realNameDialog{
		padding: 0.42rem 0.25rem 0;
		>header{
			@include n-row2;
			gap: 0.4rem;
			margin-bottom: 0.45rem;
			>img{
				height: 1.4rem;
			}
			>article{
				>h3{
					text-align: left;
					&::before{
						left: 0%;
						transform: none;
					}
				}
				>p{
					font-size: 0.24rem;
					margin-top: 0.1rem;
					line-height: 1.5;
					color: rgba(94, 234, 145, 0.74);
				}
			}
		}
		
		>.h-input{
			margin-bottom: 0.42rem;
			display: block;
		}
		>footer{
			margin-top: 0.9rem;
		}
		>p{
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 0.24rem;
			color: #FFAD00;
			margin-top: 0.4rem;
			display: flex;
			align-items: center;
			justify-items: center;
			gap: 0.10rem;
			>img {
				width: 0.3rem;
			}
		}
	}
	
	
</style>

	<template>
	<h-dialog :conf="conf" class="h-dialog-bottom" >
		<div class="h-realNameDialog">
			<input class="h-input" v-model="conf.form.realname" placeholder="请输入姓名" />
			<input class="h-input" v-model="conf.form.idcard" placeholder="请输入身份证号" />
			<h-input-code :conf="{phoneKey: 'phone',btn: '获取', hideIcon: true}"  :form="conf.form" />
			<p>
				<img src="@/assets/img/h52/38.png"/>
				未满18岁禁止充值，请先验证后再进行充值</p>
			<footer class="h-dialog-footer">
				<button class="cancel" @click="conf.show = false">{{ conf.cancelTxt }}</button>
				<button class="confirm" @click="realName()">{{ conf.confirmTxt }}</button>
			</footer>
		</div>
	</h-dialog>
	
	
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		show: false,
		title: '实名认证',
		cancelTxt: '取消',
		confirmTxt: '确定',
		form:{
			code:'',
			idcard:'',
			phone:'',
			realname:'',
		}
	});
	export default{
		props: {
			conf:{
				type: Object,
				default:defConf
			},
		},
		name: 'h-real-name-dialog',
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			}
		},
		data(){
			return{
				 
				loading:false,
				 
			}
		},
		computed:{
			userInfo(){
				return this.$store.state.userInfo || {}
			}
		},
		mounted(){
			this.conf.form.phone = this.userInfo.userPhone;
		},
		methods:{
			async realName(){
				const {form} = this.conf;
				const {code,realname,idcard} = form;
				if(!code) return this.$hMsg('请输入验证码',0)
				if(!realname.match(/^[\u4E00-\u9FA5A-Za-z0-9]+$/)) return this.$hMsg('请输入正确的姓名',0)
				if(!idcard.match(/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/)) return this.$hMsg('请输入正确的身份证',0)
				
				const res = await this._req(this.$api.realNameUser(form))
				if(!res) return;
				this.$hMsg('认证成功');
				this.conf.show = false;
				this.$store.dispatch('getInfo')
			},
			...tool({ keyMap:{ mergeConf:1} }),
		}
		
		
	}
	
	
</script>
