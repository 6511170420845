
<template>
	<div class="h-inputPwd"  >
		<img class="h-input-before" src="@/assets/img/h52/17.png" />
		<input  class="h-input" :type="showPwd ? 'text' : 'password'"  v-model="form[conf.k]" v-bind="conf.props" v-on="conf.on"  />
		<i @click="showPwd = !showPwd" :class="{'n-icon-pwd-show':!showPwd, 'n-icon-pwd-hide': showPwd}"></i>
	</div>
	
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		props:{
			placeholder:'请输入密码'
		},
		on:{},
		k: 'password',
	});
	export default{
		name: 'h-input-pwd',
		props: {
			form:{
				type: Object,
				default: ()=>({})
			},
			conf:{
				type: Object,
				default:defConf
			},
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			}
		},
		data(){
			return{
				vm:this,
				loading:false,
				showPwd:false,
			}
		},
	
		methods:{
			...tool({ keyMap:{ mergeConf:1} }),
		}
		
		
	}
	
</script>
