import Vue from 'vue';
import elementUI from 'element-ui';
import store from '@/store/index'
import router from '@/router/index'

const resizeEls = new Set();
const rectLoop = () => {
	const diff = (arr, keys, funcKey, idKey, exactKey) => {
		let isChange;

		for (const el of arr) {
			const rect = el.getBoundingClientRect();
			isChange = 0;
			keys.forEach(([ek, rk, ok]) => {
				const v = el[exactKey] ? rect[rk] : el[ok];
				if (el[ek] !== v) isChange = 1;
				el[ek] = v;
			});
			isChange && el[funcKey](el);
		}
	};
	diff(
		resizeEls,
		[
			["_oWidth", "width", "offsetWidth"],
			["_oHeight", "height", "offsetHeight"],
		],
		"_resizeFunc",
		"_resizeId",
		"_resizeExact"
	);
	window.requestAnimationFrame(rectLoop);
};
window.requestAnimationFrame(rectLoop);
const rectBind = (arr, keys, funcKey, idKey, vNodeKey, exactKey) => function(el, binding, vNode) {
	const rect = el.getBoundingClientRect();
	el[exactKey] = binding.modifiers && binding.modifiers.exact;
	keys.forEach(([ek, rk, ok]) => (el[ek] = el[exactKey] ? rect[rk] : el[ok]));
	el[funcKey] = binding.value;
	el[vNodeKey] = vNode;
	el[idKey] = base.uuid();
	arr.add(el);
};

Vue.directive("resize", {
	bind: rectBind(
		resizeEls,
		[
			["_oWidth", "width", "offsetWidth"],
			["_oHeight", "height", "offsetHeight"],
		],
		"_resizeFunc",
		"_resizeId",
		"_resizeVNode",
		"_resizeExact"
	),
	componentUpdated(el, binding, vNode) {
		if (!el._resizeId) return;
		el._resizeFunc = binding.value;
		el._resizeVNode = vNode;
	},
	unbind(el) {
		resizeEls.delete(el);
	},
});


const clickOutside = {
	els: new Set(),
	down: null,
	up: null,
	onDown: e=> (clickOutside.down = e.target),
	onUp(e){
		clickOutside.up = e.target;
		const {up,els,down} = clickOutside;
		els.forEach(el=> {
			const hasEls = [el];
			const type = base.isType(el._clickOutsideData);
			let cb;
			if(['object'].includes(type)){
				cb = el._clickOutsideData.func;
				hasEls.push(...(el._clickOutsideData.els || []));
			}
			if(['function'].includes(type)) cb = el._clickOutsideData;
		
			cb && down && up && hasEls.every(c=> !c || !c.contains(down) && !c.contains(up)) && cb(el);
		} )
	}
}

document.addEventListener('mousedown',clickOutside.onDown);
document.addEventListener('touchstart',clickOutside.onDown);
document.addEventListener('mouseup',clickOutside.onUp);
document.addEventListener('touchend',clickOutside.onUp);

Vue.directive('click-outside', {
	bind(el, binding, vNode) {
		el._clickOutsideData = binding.value;
		el._clickOutsideVNode = vNode;
		el._clickOutsideId =  base.uuid();
		el._clickOutside = clickOutside;
		clickOutside.els.add(el);
	},
	componentUpdated(el, binding, vNode){
		if(!el._clickOutsideId) return;
		el._clickOutsideData = binding.value;
		el._clickOutsideVNode = vNode;
	},
	unbind(el){
		clickOutside.els.delete(el);
	}
})

Vue.directive('ref', {
	bind(el, binding, vNode) {
		binding.value({el,binding, vm: vNode && vNode.componentInstance, vNode});
	},
	componentUpdated(el, binding, vNode, oldVNode){
		binding.value({el,binding, vm: vNode && vNode.componentInstance, vNode, oldVNode});
	}
})


Vue.mixin({
	methods:{
		async _req(p,tg = this){
			if(!p) return false;
			tg = tg || this;
			
			tg.loading = true;
			const res = await p;
			tg.loading = false;
			return res.code === 200 && res;
		},
		
		
	}
})


export const base = {
	getFps: ({dur = 1000} = {}) =>new Promise(r=>{
		let end = 0, i = 0;
		const count = ()=>{
			if(end) return;
			i++;
			window.requestAnimationFrame(count)
		}
		window.requestAnimationFrame(count)
		setTimeout(()=>{
			end = 1;
			r(i);
		},dur);
	}),
	math: {
		smallCalculate(a, b, syb) {
			let [aPatch, bPatch, patch] = [0, 0, 0];
			a += '';
			b += '';
			if (a.includes('.')) aPatch = a.split('.')[1].length;
			if (b.includes('.')) bPatch = b.split('.')[1].length;
			patch = aPatch > bPatch ? aPatch : bPatch; 
				
			// 调整小数位化成整数
			if (patch > 0) {
				a = a.replace('.', '') * 1;
				b = b.replace('.', '') * 1;
				
				if (aPatch > bPatch)  b *= (10 ** (aPatch - bPatch));
				else if (aPatch < bPatch) a *= (10 ** (bPatch -aPatch));
			}
				
			a *= 1;
			b *= 1;
			let res;
	
			switch (syb) {
				case '+': res = a + b; break;
				case '-': res = a - b; break;
				case '*': res = this.multiply ? this.multiply(a,b) : a * b; break;
				case '/': res = a / b; break;
				default: return 0;
			}
			if(syb === '/') return res;
			res = (res + '').split('');
			if (patch > 0) {
				if( syb === '*') patch *= 2;
				if (res.length >= patch)  res.splice(res.length - patch, 0, '.')
				else  res.unshift( '.' + new Array(patch - res.length).fill(0).join('')  )
			}
				
			return res.join('') * 1;
	
		}
	},
	clone(o,opt = {}) {
		const { filterKey } = opt;
		let res;
		const type = this.isType(o);
		if (['object','array'].includes(type)) {
			res = type === 'array' ? [] : {};
			for (let k of Object.keys(o)) {
				if( base.isType(filterKey) === 'function' && !filterKey(k,o) ) continue;
				res[k] = base.clone(o[k],opt);
			}
		}
		else res = o;
		
		return res;
	},
	copyText(txt){
		const input = document.createElement('textarea');
		input.value = txt;
		input.style.left = '-9999px';
		input.style.position = 'fixed';
		document.body.appendChild(input)
		input.select();
		document.execCommand('copy');
		input.remove()
	},
	file:{
		getSuffix(name){
			return name.slice( name.lastIndexOf('.')+1 );
		},
		test({files, maxSize ,types}){
			if(!files || !files.length) return 0;
			
			
			for(const file of files){
				if(maxSize && maxSize < file.size) return 0;
				if(types && !types.includes( base.file.getSuffix(file.name).toLowerCase()  ) ) return 0;
			}
			
			return 1;
		},
	},
	coordinate:{
		getMouseXYByBox({e,tg, single = true, outArr = 1, boxScale = 1}){
			const {x,y,width:w,height:h} = tg.getBoundingClientRect();
			const points = [...(e.type.toLowerCase() === 'touchend' ? e.changedTouches : e.targetTouches || [e])].map(({clientX:ox,clientY:oy})=>{
				if( base.isType(ox) !== 'number' || base.isType(oy) !== 'number') return outArr ? [] : {};
				
				ox -= x;
				oy -= y;
				ox = ox < 0 ? 0 : ox > w ? w : ox;
				oy = oy < 0 ? 0 : oy > h ? h : oy;
				// 盒子如果进行缩放了，则这边要处理缩放后坐标
				ox /= boxScale;
				oy /= boxScale;
				
				return outArr ? [ox,oy] : {x:ox,y:oy};
			});
			
			if(single) return points[0];
			
			return single ? points[0] : points;
		},
		getCartesianToPolarAngle(x,y,cx,cy){
			const r = Math.atan2(y - cy, x - cx);
			const angle = r / Math.PI * 180;
			return (angle + 180) % 360;
		},
		cartesianToPolar(x,y){
			return {r: Math.sqrt(x*x + y*y), a: Math.atan2(y,x) }
		},
		polarToCartesian(a,r,outArr){
			const [x,y] = [r * Math.cos(a),r * Math.sin(a) ]
			return outArr ? [x,y] : {x,y}
		},
	},
	debounce:{
		data: [],
		commit(name, fun,  interval = 1000){
			if(!name) return;
			let tg = this.data.find( v => v.name === name );
			if(!tg){
				tg = { name,   interval};
				this.data.push(tg);
			}
			
			clearTimeout( tg.timer);
			tg.fun = fun;
			
			const run = ()=>{
				fun();
				
				this.delItem( this.data, tg );
			}
			if(interval === 0) return  run();
			tg.timer = setTimeout( run, interval);
			
		},
		delItem(arr,k,fn,single){
			for(let i = 0; i < arr.length; i++){
				const item = arr[i];
				if( !(fn ? fn(item,i,arr) : item === k) ) continue;
				arr.splice(i,1);
				i--;
				if(single) return;
			}
		},
	},
	rem({w = 750,max} = {}){
		const {innerWidth:vw} = window;
		document.querySelector('html').style.fontSize = (max && (vw > max) && max || vw) / w * 100 + 'px';
	},
	date:{
		repairZero(s,len = 2, z = '0', dir = 1){
			if((s+'').length >= len) return s;
			const t = new Array(len - (s+'').length).fill(z).join('');
			return dir ? t + s : s + t;
		},
		local(type,dateObj){
			dateObj = dateObj || new Date();
			const [date,time] = [
				`${dateObj.getFullYear()}-${this.repairZero(dateObj.getMonth() + 1 )}-${this.repairZero(dateObj.getDate())}`,
				`${this.repairZero(dateObj.getHours())}:${this.repairZero(dateObj.getMinutes())}:${this.repairZero(dateObj.getSeconds())}`
			]
			
			switch(type){
				case 'date': return date;
				case 'time': return time;
				default: return date + ' ' + time;
			}
		},
		format(str,date){
			date = date || new Date();
			const {repairZero} = base.date;
			const [y,M,d,h,m,s,S, t] = [date.getFullYear(), date.getMonth(), date.getDate() - 1, date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds(), date.getTime() ];
			const reg = {
				yy: y % 100,
				yyyy: y,
				M: M+1,
				MM: repairZero( M + 1),
				d: d + 1,
				dd: repairZero( d + 1),
				H: h,
				HH: repairZero( h),
				m,
				mm: repairZero( m),
				s,
				ss: repairZero( s),
				S,
				SSS: repairZero(S, 3), 
				t, 
			};
			['yyyy','yy','MM','M','dd','d','HH','H','mm','m','ss','s','SSS','S','t'].forEach(r=> str = str.replace( new RegExp(`${r}`,'g' ), reg[r] ) ) 
			
			return str;
		},
	},
	base64ToUnit8(s,fileName){
		const arr = s.split(","),
			binary = atob(arr[1]),
			u8 = new Uint8Array(binary.length);
		let i = binary.length;
		
		while (i--) {
			u8[i] = binary.charCodeAt(i);
		}
		
		return new File( [u8], fileName || `文件.${arr[0].match(/\/([^\/;]+);/)[1]}`, { type: arr[0].match(/:([^]*);/)[1] } );
	},
	
	params:{
		merge(o,stand, opt = {}){
			const defStrategy = (k,a,b)=> [undefined].includes(a) ? b : a;
			const { strategy = defStrategy, k } = opt;
			
			const type1 = base.isType(o);
			const type2 = base.isType(stand);
			let res;
			
			if( type1 === type2 && ['object'].includes(type2) ){
				for (let k of Object.keys(stand)) {
					o[k] = base.params.merge( o[k], stand[k],{strategy, k });
				}
				res = o;
			}
			else res = strategy(k, o, stand);
			
			return res
		},
		
		optional(o, opt = {},p){
			opt = {
				autoBindThis: true,
				...opt
			}
			return new Proxy({__v:o,__parent: p}, {
				get(tg,k){
					if( ['__v','__parent'].includes(k) ){
						if(opt.autoBindThis && k === '__v' && tg.__parent && base.isType(tg.__v) === 'function') return tg.__v.bind(tg.__parent) ;
						return tg[k];
					};
					
					let v;
					try{ v = tg.__v[k] }
					catch(e){ v = undefined }
					return base.params.optional(v,opt, tg.__v);
				},
				set(){
					console.error('在__v属性里获取实际值来设置属性，不要在proxy里设置')
					return false;
				}
			
			})
		},
		
		formatLineObj(o){
			for(const k of Object.keys(o)){
				const line = k.split('.');
				if(line.length === 1) continue;
				
				let tg = o;
				line.forEach((k2,idx)=> {
					if( idx === line.length - 1 ) return tg[k2] = o[k];
					tg[k2] = tg[k2] || {};
					tg = tg[k2];
				} )
				
			}
			return o;
		},
		clearEmpty( o , arr = [null, 'NaN', undefined, '']){
			const res = {};
			Object.keys(o).forEach(k=> !arr.some(n=> {
				if(n === 'NaN' ) return o[k] !== o[k];
				if(n === '[]') return base.isType(o[k]) === 'array' && !o[k].length;
				if(n === '{}') return base.isType(o[k]) === 'object' && !Object.keys(o[k]).length;
				if(base.isType(n) === 'function') return n({k,v:o[k],tg:o, res, rule:arr });
				return  o[k] === n;
			} ) && (res[k] = o[k]) );
			return res;
		},
		objPropertyNameChange(o, type = 'humpToSplit', symbol = "_"){
			if(base.isType(o) !== 'object') return o;
			const arr = Object.keys(o).map(k=> [o,k]);
			while(arr.length){
				const [o,k] = arr.shift();
				const v = o[k];
				delete o[k];
				if(type === 'humpToSplit') k = this.humpToSplit(k,symbol)
				if(type === 'splitToHump') k = this.splitToHump(k,symbol)
				o[k] = v;
				
				if(base.isType(v) === 'object') arr.unshift(...Object.keys(v).map(k=> [v,k]))
			}
			
			return o;
		},
		
		humpToSplit(s, symbol = '_'){
			s+='';
			if(!s.length) return s
			return  s[0] + s.slice(1).replace(/([A-Z])/g, txt => symbol + txt.toLowerCase() )
		},
		splitToHump(s,symbol = "_"){
			s+='';
			if(!s.length) return s
			return  s.split(symbol).reduce((a,b)=> ( ['',undefined,null].includes(a) ? symbol : a) + ( ['',undefined,null].includes(b) ? symbol : b)[0].toUpperCase() + b.slice(1)  );
		},
		formatUrlSearch(url,decode = 1){
			let search = (url + '').match(/\?([^\?\#]+)/);
			search = search && search[1] || url;
			if(!search) return {};
			const params = {};
			search.split('&').forEach(v=>{
				v = (decode ? decodeURIComponent(v) : v).split('=');
				params[v[0]] = v.slice(1).join('=');
			})
			
			return params;
		},
		formatObjToUrlSearch(params, url,encode = 1){
			if( !['array','object'].includes(base.isType(params))  || !Object.keys(params).length ) return url || '';
			const search = Object.entries(params).map(([k,v])=> `${ encode ? encodeURIComponent(k) : k }=${ encode ? encodeURIComponent(v) : v}` ).join("&");
			if(!url) return search;
			
			url+='';
			const reg = /\?([^\?\#]+)/;
			
			return url.match(reg) ? url.replace( reg, s=>{
				const oParams = base.params.formatUrlSearch(url,0);
				return `?${base.params.formatObjToUrlSearch(oParams,0)}&${search}`;
			} ) : `${url}?${search}`;
		},
		
		
		
	},
	random: (min,max,containMax) => Math[containMax ? 'round' : 'floor'](Math.random() * (max - min)) + min,
	isType: o=> Object.prototype.toString.call(o).match(/\[object\s([a-zA-Z0-9]+)\]/)[1].toLowerCase(),
	arr:{
		delItem(arr,k,fn){
			const idx = arr.findIndex((item,idx,arr)=> fn ? fn(item,idx,arr) : item === k);
			idx >= 0 && arr.splice(idx,1);
		},
		filterMap(arr,fn){
			const res = [];
			arr.forEach((v,idx)=> {
				const r = fn(v,idx,arr);
				r && res.push(r);
			})
			return res;
		},
	},
	download(url, fileName) {
		const a = document.createElement("a");
		a.href = url;
		a.target = "_blank";
		a.download = fileName || url;
		a.click();
		setTimeout(() => a.remove(), 3000);
	},
	testFile({ files, maxSize, types }) {
		if (!files || !files.length) return 0;
	
		for (const file of files) {
			if (maxSize && maxSize < file.size) return 0;
			if (
				types &&
				!types.includes(
					file.name.slice(file.name.lastIndexOf(".") + 1).toLowerCase()
				)
			)
				return 0;
		}
	
		return 1;
	},
	uuid(){
		let d = new Date().getTime();
		return (
			"n__" +
			"xxxxxxxx_xxxx_6xxx_yxxx_xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
				let r = (d + Math.random() * 16) % 16 | 0;
				d = Math.floor(d / 16);
				return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
			})
		);
	},
	
}

Vue.prototype.$base = base;





export const req = async opt=> {
	// 正式 https://bydskins.com/kings 测试http://146.56.214.228/kings
	let { baseUrl = process.env.VUE_APP_BASEURL,noMsg, noVerif ,url,headers = {}, params, data, method = "post", 	resType = "json", } = opt;
	url = url.match(/http/) ? url : baseUrl + url;
	method = method.toUpperCase(),
	opt = {
		method,
		...opt,
		headers: { "Authorization": store.state.token },
	};
	if(data && ['POST','PUT','DELETE'].includes(method)){
		const isJson = ['object','array'].includes( base.isType(data));
		opt.body = isJson ? JSON.stringify(data) : data;
		isJson && (opt.headers["Content-Type"] = "application/json" );
	}
	if(params) url = base.params.formatObjToUrlSearch( params, url);
	
	opt.headers = {...opt.headers, ...headers}
	
	Object.entries(opt.headers).forEach(([k,v])=> [null,undefined].includes(v) && (delete opt.headers[k]) );
	
	const res = await window.fetch(url, opt).then((res) => res[resType]()).catch((err) => {
		console.log(err, "err");
		return resType === "json" ? { status: false, err: 1 } : false;
	});
	if(resType === 'json' && res.code !== 200 && !noMsg) hMsg(res.msg || '网络繁忙',0);
	
	if(!noVerif && res.code === 401){
		store.state.token && hMsg(res.msg || '登陆过期',0);
		store.commit('SET_AND_STORAGE',{k: 'userInfo', v: '', encode:0});
		store.commit('SET_AND_STORAGE',{k: 'token', v: '', encode:0});
		// router.replace({ path:'/h5/home', query: {_navLogin:1} });
	}
	return  res
}

Vue.prototype.$req = req;





export const bus = {
	events: [],
	on(name,handler){
		this.un(name)
		this.events.push({ name, handler })
	},
	emit(name){
		for(const event of this.events){
			if(name === event.name) return event.handler( ...[...arguments].slice(1))
		}
	},
	un(name){
		for(let i = 0; i < this.events.length; i++){
			if(name !== this.events[i].name) continue;
			this.events.splice(i,1);
			i--;
		}
	}
}


Vue.prototype.$bus = bus;





import hMsgComp from '@/components/h5/msg.vue';
export const hMsg = (...args) =>{
	if(!hMsg.vm) hMsg.init();
	hMsg.vm.msg(...args)
}
hMsg.init = ()=>{
	if (Vue.prototype.$isServer) return;
	if(hMsg.vm) hMsg.unMounted();
	const vm = hMsg.vm = new Vue(hMsgComp);
	vm.$mount();
	document.body.appendChild(vm.$el);
}
hMsg.unMounted = ()=>{
	this.vm.$el && this.vm.$el.remove();
	hMsg.vm.$destroy();
	hMsg.vm = null;
}

Vue.prototype.$hMsg = hMsg;



export const msg = (message = '操作成功',type = 1) => elementUI.Message({  type:  ['','success','error','warning','info'][type], message});
Vue.prototype.$msg = msg;





import hMsgDialogComp from '@/components/h5/msg-dialog.vue';
export const hMsgDialog = {
	vm: null,
	init(){
		if (Vue.prototype.$isServer) return;
		if(this.vm) this.unMounted();
		const vm = this.vm = new Vue(hMsgDialogComp);
		vm.$mount();
		document.body.appendChild(vm.$el);
	},
	toggle(conf){
		if(!this.vm) this.init();
		this.vm.toggle(conf)
	},
	unMounted(){
		this.vm.$el && this.vm.$el.remove();
		this.vm.$destroy();
		this.vm = null;
		
	}
}
Vue.prototype.$hMsgDialog = hMsgDialog;

Vue.filter('beforePipe', (value) => {
  if (typeof value === 'string') {
      const index = value.indexOf('|');
      if (index !== -1) {
          return value.substring(0, index); // 包含 |
      }
  }
  return ''; // 如果没有 | 符号，则返回空字符串
})

Vue.filter('afterPipe', (value) => {
  if (typeof value === 'string') {
    const index = value.indexOf('|');
    if (index !== -1) {
        return value.substring(index + 1); // 不包含 |
    }
  }
  return value;
})










