<style lang="scss" scoped>
	.h-loginDialog{
		.h-loginDialog-tabs{
			// margin-top: 0.4rem;
			@include n-row2;
			// gap:0.24rem;
			background: #101010;
			>li{
				// flex-shrink: 0;
				flex: 1;
				// background: url(@/assets/img/h5/21.png) no-repeat center / 100% 100%;
				height: 0.66rem;
				padding: 0 0.2rem;
				white-space: nowrap;
				min-width: 1.7rem;
				@include n-row2;
				color: rgba(209, 227, 241, 1);
				font-size: 0.3rem;
			}
			.h-loginDialog-tabs-cur{
				font-weight: 600;
				font-size: 0.3rem;
				color: #000000;
				background: #FFAD00;
				box-shadow: 0rem 0.02rem 0.08rem 0rem rgba(255,114,0,0.04);
				// background: url(@/assets/img/h5/19.png) no-repeat center / 100% 100%;
			}
		}
		.h-loginDialog-tabs2{
			margin-top: 0.33rem;
			@include n-row2;
			padding: 0 0.47rem;
			gap:0.73rem;
			>li{
				height: 0.57rem;
				font-size: 0.28rem;
				color: rgba(173, 171, 193, 0.95);
				flex-shrink: 0;
			}
			.h-loginDialog-tabs2-cur{
				position: relative;
				font-weight: 600;
				color: #FFC300;
				&::before{
					left: 50%;
					transform: translate(-50%);
					bottom: 0;
					position: absolute;
					content: ' ';
					width: 0.35rem;
					background: #FFC300;
					height: 0.05rem;
				}
			}
		}
		.h-loginDialog-form{
			padding: 0 0.47rem;
			margin-top: 0.33rem;
		}
		.h-input,.h-inputCode, .h-inputPwd{
			margin-bottom: 0.27rem;
			.h-input{
				margin: 0;
			}
		}
		.h-loginDialog-tip{
			align-items: flex-start;
			margin-bottom: 0.27rem;
			font-size: 0.24rem;
			line-height: 1.6;
			color: rgba(255, 255, 255, 0.57);
			gap:0.17rem;
			@include n-row1;
		}
		
	}
	
	
</style>

<template>
	<h-dialog :conf="conf" v-loading="loading">
		<img class="h-dialog-close" src="@/assets/img/h52/close.png" @click="conf.maskClose && (conf.show = false)"/>
		<header class="h-dialog-head">
			<img  src="@/assets/img/h52/19.png" />
		</header>
		<div class="h-loginDialog">
			<ul class="h-loginDialog-tabs">
				<li v-for="item in tabsConf.list" :class="{'h-loginDialog-tabs-cur': tabsConf.cur === item.value }" :key="item.value" @click="tabsConf.cur = item.value">{{item.label}}</li>
			</ul>
			
			<ul class="h-loginDialog-tabs2" v-show="tabsConf.cur === 1">
				<li v-for="item in tabsConf.list2" :class="{'h-loginDialog-tabs2-cur': tabsConf.loginType === item.value }" :key="item.value" @click="tabsConf.loginType = item.value">{{item.label}}</li>
			</ul>
			<div class="h-loginDialog-form loginForm">
				<h-input :form="form" :conf="{k: 'userPhone', props:{placeholder: '请输入账号'}}" />
				
				<h-input-pwd :form="form" :conf="{k: 'userPwd'}" v-show="tabsConf.cur === 2 || tabsConf.cur === 1 && tabsConf.loginType === 1" />
				<h-input-pwd  :form="form" :conf="{k: 'userPwd', props:{placeholder: '请输入新的密码'}}" v-show="tabsConf.cur === 3" />
				
				
				<h-input-code :form="form" v-show="tabsConf.cur !== 1 || tabsConf.loginType === 2" />

				<!-- <input class="h-input" v-show="tabsConf.cur === 2" v-model="form.tgUserSelfCode" placeholder="请填写邀请码（选填）" /> -->
				<h-input :form="form" v-show="tabsConf.cur === 2" :conf="{k: 'tgUserSelfCode', props:{placeholder: '请填写邀请码（选填）'}}" />
				
				<p class="h-loginDialog-tip"><span :class="{'h-checkBox':1,'h-checkBoxCheck': form.check}" @click="form.check = !form.check"><i></i></span><em>我已满18岁，阅读并同意<a target="_blank" href="./#/h5/agree/user">《用户协议》</a>和<a  target="_blank"  href="./#/h5/agree/privacy">《隐私政策》</a></em></p>
				<!-- <p class="h-loginDialog-tip">
					<span :class="{'h-checkBox':1,'h-checkBoxCheck': form.check}" @click="form.check = !form.check"><i></i></span>
					<el-checkbox v-model="form.checked" fill="#FFAD00"></el-checkbox>
					<em>我已满18岁，阅读并同意<a target="_blank" href="./#/h5/agree/user">《用户协议》</a>和<a  target="_blank"  href="./#/h5/agree/privacy">《隐私政策》</a></em></p> -->

				<footer class="h-dialog-footer" v-show="tabsConf.cur === 1"><button @click="sign">登录</button></footer>
				<footer class="h-dialog-footer" v-show="tabsConf.cur === 2"><button @click="reg">注册</button></footer>
				<footer class="h-dialog-footer" v-show="tabsConf.cur === 3"><button @click="updatePwd">修改</button></footer>
			</div>
		</div>
	</h-dialog>
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		show:false
	});
	export default{
		name: 'h-login-dialog',
		props: {
			conf:{
				type: Object,
				default:defConf
			},
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			}
		},
		data(){
			return{
				loading:false,
				form:{
					userPhone: '',
					userPwd: '',
					check:true,
					code:'',
					tgUserSelfCode:'',
				},
				tabsConf:{
					cur:1,
					list:[
						{ label:'登 录',  value: 1 },
						{ label:'注 册',  value: 2 },
						{ label:'忘记密码',  value: 3 },
					],
					loginType:1,
					list2:[
						{ label:'密码登录',  value: 1 },
						{ label:'验证码登录',  value: 2 },
					],
				},
				
			}
		},
		
		methods:{
			async updatePwd(){
				if( !['userPhone','check', 'userPwd','code' ].every(k=> this.verif(k)) ) return;
				const res = await this._req(this.$api.updatePwdUser(this.form))
				if(!res) return;
				this.$hMsg('修改成功,自动登录中...');
				this.tabsConf.loginType = this.tabsConf.cur = 1;
				this.sign();
			},
			verif(k){
				const {form} = this;
				const v = form[k];
				if(k === 'check' && !v) return (this.$hMsg('请同意协议',0),0);
				if(k === 'userPhone' && !v.match(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)) return (this.$hMsg('请输入正确的手机号码',0),0);
				if(k === 'userPwd' && v.length < 6 || v.length > 14) return (this.$hMsg('密码最小6位最大14位~',0),0);
				if(k === 'code' && !v) return (this.$hMsg('请输入验证码',0),0);
				return 1;
			},
			async reg(){
				const {form,verif} = this;
				if( !['userPhone','check', 'code' ].every(k=> this.verif(k)) ) return;
				if(!form.tgUserSelfCode){
					// form.tgUserSelfCode = 'YS888'
				}
				const res = await this._req(this.$api.regUser(form) );
				if(!res) return; 
				this.$hMsg('注册成功,自动登录中...');
				this.tabsConf.loginType = this.tabsConf.cur = 1;
				this.sign();
			},
			async sign(){
				const {form,tabsConf:{loginType}} = this;
				if( !['userPhone','check', loginType === 1 ? 'userPwd' : 'code' ].every(k=> this.verif(k)) ) return;
				const res = await this._req(this.$store.dispatch('login',{type:loginType,form }));
				if(!res) return; 
				
				this.$hMsg('登录成功');
				this.conf.show = false;
			},
			...tool({ keyMap:{ mergeConf:1} }),
		},
		created() {
        const currentUrl = window.location.href;
        const startIndex = currentUrl.indexOf("bydskins.com/") + "bydskins.com/".length;  //http://146.56.214.228/
        const endIndex = currentUrl.indexOf("#");
        const extractedString = currentUrl.substring(startIndex, endIndex);
        if(extractedString.length>0){
            this.form.tgUserSelfCode = extractedString;
        }
    },
		
	}
	
</script>
