
<template>
	<div class="h-inputCode"  >
		<img v-if="!conf.hideIcon" class="h-input-before" src="@/assets/img/h52/16.png" />
		<input class="h-input"  v-model="form[conf.k]" v-bind="conf.props" v-on="conf.on"  />
		<button @click="sendMsg()" v-loading="loading" :disabled="down > 0">{{ down > 0 ? down + 's' : conf.btn}}</button>
	</div>
	
	
	
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		props:{
			placeholder:'请输入验证码'
		},
		phoneKey: 'userPhone',
		on:{},
		k: 'code',
		btn: '发送',
		hideIcon: false,
	});
	export default{
		name: 'h-input-code',
		props: {
			form:{
				type: Object,
				default: ()=>({})
			},
			conf:{
				type: Object,
				default:defConf
			},
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			}
		},
		data(){
			return{
				vm:this,
				loading:false,
				down:0,
			}
		},
		mounted(){
			this.timer = setInterval(()=> this.down > 0 && (this.down--),1000);
		},
		beforeDestroy(){
			clearInterval(this.timer)
		},
		methods:{
			async sendMsg(){
				const userPhone = this.form[this.conf.phoneKey];
				if( !(userPhone + '').match(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/)  ) return this.$hMsg('请输入正确的手机号码',0);
				const method = this.form.sendApi ? this.$api[this.form.sendApi] : this.$api.getCodeUser;
				const res = await this._req(method({userPhone}))
				if(!res) return;
				this.down = 120;
				this.form.hasSendMsg = true
				this.$hMsg('发送成功',0);
			},
			...tool({ keyMap:{ mergeConf:1} }),
		}
		
		
	}
	
</script>
