<style lang="scss" scoped>
	.h-msgDialog{
		z-index: 1900;
		.h-msgDialog-content{
			background: #22201E;
			width: calc(100% - 0.5rem);
			z-index: 1;
			@include n-col1;
			.content {
				padding: 0 0.25rem;
				text-align: center;
				color: #fff;
				font-weight: 500;
				font-size: 0.28rem;
				color: #FFFFFF;
				.tip-img {
					width: 0.88rem;
					margin: 0.3rem 0 0.1rem;
				}
			}
			>header{
				margin-bottom: 0.32rem;
			}
			>article{
				text-align: center;
				line-height: 1.7;
				font-size: 0.26rem;
				color: #fff;
				padding: 0.32rem 0.3rem 0 0.3rem;
			}
			>footer{
				margin-top: 0.64rem;
				margin: 0.64rem 0.25rem 0.3rem;
				gap:0.36rem;
			}
		}
	}
	
	
</style>

<template>
	<h-dialog class="h-msgDialog" :conf="conf" customContent>
		<div class="h-msgDialog-content n-dialog-content">
			<div class="h-title" v-if="conf.title">
				<div>{{ conf.title }}</div>
				<img src="@/assets/img/h52/close2.png" @click="confirm('cancel')"/>
			</div>
			<div class="content"v-if="conf.content">
				<img class="tip-img" src="@/assets/img/h52/23.png"/>
				<div>{{ conf.content }}</div>
			</div>
			<article v-if="conf.contentHtml" v-html="conf.contentHtml"></article>
			<footer class="h-dialog-footer">
				<button class="cancel" v-if="conf.cancelShow" @click="confirm('cancel')">{{ conf.cancelTxt }}</button>
				<button class="confirm" v-if="conf.confirmShow" @click="confirm('confirm')">{{ conf.confirmTxt }}</button>
			</footer>
		</div>
	</h-dialog>
	
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		show:false,
		cancelShow:1,
		confirmShow:1,
		cancelTxt: '取消',
		confirmTxt: '确定',
		onCancel: conf=> conf.show = false,
		onConfirm: null,
		title: '',
		content: '',
		contentHtml: '',
	});
	export default{
		name: 'h-msg-dialog',
		props: {
			conf:{
				type: Object,
				default:defConf
			},
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			}
		},
		data(){
			return{
				
			}
		},
		mounted(){
			
		},
		methods:{
			toggle(conf){
				this.mergeConf(conf,defConf());
				Object.entries(conf).forEach(([k,v])=> this.conf[k] = v);
				this.conf.show = true;
			},
			confirm(type){
				const {conf} = this;
				const {onCancel, onConfirm} = conf;
				if(onCancel && type === 'cancel') onCancel(conf);
				if(onConfirm && type === 'confirm') onConfirm(conf);
				this.$emit(type, {type, conf})
			},
			...tool({ keyMap:{ mergeConf:1} }),
		}
		
		
	}
	
	
</script>
