
<template>
	
	<ul class="h-checkBtns-flex" >
		<li :class="{'h-checkBtns-item':1, 'h-checkBtns-cur': item.value === value}" :style="{'grid-column':item.grid}" v-for="item in list" :key="item.value" @click="$emit('input',item.value)" >{{item.label}}</li>
	</ul>
	
</template>

<script>
	
	export default{
		name: 'h-check-btns',
		props: {
			list:{
				type: Array,
				default:()=>[]
			},
			value: [String,Number],
		},
		data(){
			return{
				
			}
		},
		mounted(){
			
		},
		methods:{
		}
		
		
	}
	
</script>
