
<template>
	
	<div class="h-numRange" >
		<input @change="change(conf.min,'input1')" @input="verifNum('input1')" @compositionstart="isPinyin = 1" ref="input1" @compositionend="verifNum('input1',isPinyin = 0)" :value="form[conf.min.k]" v-bind="conf.min.props" v-on="conf.min.on" />
		<span>{{conf.split}}</span>
		<input @change="change(conf.max,'input2')" @input="verifNum('input1')" @compositionstart="isPinyin = 1" ref="input2" @compositionend="verifNum('input2',isPinyin = 0)" :value="form[conf.max.k]" v-bind="conf.max.props" v-on="conf.max.on" />
		<!-- <footer><button @click="conf.reset({vm,conf})">重置</button></footer> -->
	</div>
	
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		min:{ 
			k: 'min',
			precision: -1,
			props:{
				placeholder: '请输入最低价格',
			},
			on:{}
		},
		max:{
			k: 'max',
			precision: -1,
			props:{
				placeholder: '请输入最高价格',
			},
			on:{}
		},
		split: '至',
		reset: ({conf:{min,max},vm})=> vm.form[min.k] = vm.form[max.k] = ''
	});
	export default{
		name: 'h-num-range',
		props: {
			form:{
				type: Object,
				default: ()=>({})
			},
			conf:{
				type: Object,
				default:defConf
			},
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			}
		},
		data(){
			return{
				vm:this,
				isPinyin:0,
			}
		},
		mounted(){
			
		},
		methods:{
			verifNum(name){
				if(this.isPinyin) return;
				const el = this.$refs[name]
				const lastV = el.value;
				const v = lastV.replace(/[^\d\.]/g,'');
				if(v !== lastV) el.value = v || 0;
			},
			change({ k, precision},name){
				const {form,$base:{isType}} = this;
				let v = this.$refs[name].value * 1;
				const lastV = form[k];
				v = isType(v) === 'number' && v === v ? v : 0;
				if (!this.$refs[name].value) { // 置空
					v = ''
				}
				if(precision >= 0) v = v.toFixed(precision) * 1;
				if(lastV === v) return;
				this.$set(form,k,v)
				this.$emit('change',{vm:this,form,v,k, conf:this.conf});
			},
			...tool({ keyMap:{ mergeConf:1} }),
		}
		
		
	}
	
</script>



