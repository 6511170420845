
<template>
	
	<div class="h-num" >
		<i :class="{'n-icon-minus':1, 'h-num-disabled': $base.isType(conf.min) === 'number' && form[conf.k] <= conf.min}" @click="change(-1)"></i>
		<input @compositionstart="isPinyin = 1" @compositionend="verifNum(isPinyin = 0)" ref="input" @input="verifNum" @change="change(0)" :value="form[conf.k]" v-bind="conf.props" v-on="conf.on" />
		<i  :class="{'n-icon-plus':1, 'h-num-disabled': $base.isType(conf.max) === 'number' && form[conf.k] >= conf.max}"   @click="change(1)"></i>
	</div>
	
	
</template>

<script>
	import tool from '@/components/conf/tool.js';
	export const defConf = ()=>({
		props:{},
		k:'val',
		on:{},
		min:0,
		precision:0,
		max:null,
	});
	export default{
		name: 'h-num',
		props: {
			conf:{
				type: Object,
				default:defConf
			},
			form:{
				type: Object,
				default: ()=>({})
			},
		},
		watch:{
			conf:{
				immediate:true,
				handler(conf,last){
					if(conf !== last) this.mergeConf(conf,defConf());
				}
			},
		},
		data(){
			return{
				isPinyin:0,
			}
		},
		mounted(){
			this.change(0);
		},
		methods:{
			verifNum(){
				if(this.isPinyin) return;
				const lastV = this.$refs.input.value;
				const v = lastV.replace(/[^\d\.]/g,'');
				if(v !== lastV) this.$refs.input.value = v || 0;
			},
			change(n){
				const {conf:{k,min,max,precision},form,$base:{isType}} = this;
				let v = this.$refs.input.value * 1;
				const lastV = form[k];
				v = isType(v) === 'number' && v === v ? v : 0;
				
				v += n;
				if( isType(max) === 'number' && v > max ) v = max;
				if( isType(min) === 'number' && v < min ) v = min;
				if(precision >= 0) v = v.toFixed(precision) * 1;
				if(lastV === v) return;
				this.$set(form,k,v)
				this.$emit('change',{vm:this,form,v,conf:this.conf});
			},
			...tool({ keyMap:{ mergeConf:1} }),
		}
		
		
	}
	
</script>



